import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import { matchPath, useLocation } from 'react-router';
import useIsMobile from 'hooks/useIsMobile';
import { isPublishedAtLeastOnce } from 'contexts/current-user-context/CurrentUserContext.utils';
import Routes from '../../../routes';
import { userSelector } from '../../../selectors';
import { useSelector } from '../../../utils/hooks/useRedux';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { breakpoints } from '../../../variables';
import LogoZone from './components/logo-zone/LogoZone';
import MainMenu from './components/main-menu/MainMenu';
import MainMenuMobileToggle from './components/main-menu-mobile-toggle/MainMenuMobileToggle';
import UsernavMenu from './components/usernav-menu/UsernavMenu';
import MainMenuMobileContext from './context/MainMenuMobileContext';
import {
  getHeaderProfileConfiguration,
  getHeaderLogoVariant,
  getHeaderVariant,
} from './utils/config';

import styles from './Header.module.scss';
import Switch from './components/switch/Switch';
import InformationBanner from './components/information-banner/InformationBanner';

type HeaderProps = {
  informationBanner: boolean;
};

const Header = ({ informationBanner }: HeaderProps) => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const reduxCurrentUser = useSelector(userSelector);

  const { main, connected } = getHeaderProfileConfiguration(
    reduxCurrentUser,
    pathname,
  );
  const headerLogo = getHeaderLogoVariant(reduxCurrentUser, pathname);
  const headerVariant = getHeaderVariant(reduxCurrentUser, pathname);

  const { currentUser, setSwitchState } = useContext(CurrentUserContext);

  // handle main menu for mobile behavior
  const [isMainMenuMobileOpened, setMainMenuMobileOpened] = useState(false);

  useEffect(() => {
    if (
      !isPublishedAtLeastOnce(currentUser) &&
      currentUser?.premiumAmbassador
    ) {
      setSwitchState?.('ambassador');
    }
  }, [currentUser]);

  const shouldShowSwitch =
    headerVariant === undefined &&
    currentUser?.isAmbassador

  // do not display on mobile
  // for conversation view
  const { width: viewportWidth } = useWindowSize();
  const desactivatedForCurrentRoute = matchPath(pathname, Routes.conversation);
  function toggleMainMenuMobile() {
    setMainMenuMobileOpened(!isMainMenuMobileOpened);
  }
  if (viewportWidth < breakpoints.sm && desactivatedForCurrentRoute) {
    return <></>;
  }
  return (
    <>
      <MainMenuMobileContext.Provider
        value={{
          isMainMenuMobileOpened,
          setMainMenuMobileOpened,
        }}
      >
        <>
          {informationBanner && <InformationBanner />}
          <header
            id="header"
            className={classnames(styles.header, {
              [styles.headerVisitor]: !reduxCurrentUser,
              [headerVariant]: true,
              [styles.headerInformationBanner]: informationBanner,
            })}
          >
            <MainMenuMobileToggle
              className={styles.mainMenuMobileToggle}
              onClick={toggleMainMenuMobile}
            />
            <LogoZone
              className={styles.logoZone}
              variant={headerLogo}
            />

            {shouldShowSwitch && !isMobile && <Switch />}

            <div className={styles.rowMenu}>
              <UsernavMenu
                currentUser={reduxCurrentUser}
                config={connected}
              />
              <MainMenu
                currentUser={reduxCurrentUser}
                config={main}
                shouldShowSwitch={shouldShowSwitch && isMobile}
              />
            </div>
          </header>
        </>
      </MainMenuMobileContext.Provider>
    </>
  );
};

export default Header;
