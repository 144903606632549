import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import { useHistory, useLocation } from 'react-router';
import './Switch.scss';
import classNames from 'classnames';
import { PublicationStatuses } from '../../../../../@types/graphql.d';
import switchTranslations from './Switch.translations';
import { t } from '../../../../../utils/translate';
import CompleteInformationsModal from './complete-informations-modal/CompleteInformationsModal';
import Blur from './blur/Blur';

type SwitchProps = {
  mobile?: boolean;
};

export default function Switch({ mobile }: SwitchProps) {
  const { currentUser, switchState, setSwitchState } =
    useContext(CurrentUserContext);
  const history = useHistory();
  const location = useLocation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isBlurry, setIsBlurry] = useState(false);
  const [isDestroying, setIsDestroying] = useState(false);

  const isSwitchOn = useMemo(() => switchState === 'ambassador', [switchState]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const switchParam = searchParams.get('switch');
    if (switchParam) {
      switch (switchParam) {
        case 'member':
          setSwitchState?.('member');
          break;
        case 'ambassador':
          setSwitchState?.('ambassador');
          break;
        default:
          break;
      }
    }
  }, [location]);

  const handleSwitchClick = () => {
      const next = isSwitchOn ? 'member' : 'ambassador';
      let redirectPath = '/dashboard';
      if (next === 'member') {
        redirectPath = '/search';
      }

      setSwitchState?.(next);
      setIsBlurry(true);
      history.push(redirectPath);
      setTimeout(() => setIsDestroying(true), 500);
      setTimeout(() => {
        setIsDestroying(false);
        setIsBlurry(false);
      }, 750);
  };

  return (
    <>
      <button
        type="button"
        className={classNames('switch', { 'switch--mobile': mobile })}
        onClick={handleSwitchClick}
      >
        <div
          className={classNames('switch__option', {
            'switch__option--toggled': !isSwitchOn,
          })}
        >
          {t(switchTranslations.search)}
        </div>
        <div
          className={classNames('switch__option', {
            'switch__option--toggled': isSwitchOn,
          })}
        >
          {t(switchTranslations.share)}
        </div>
      </button>
      <CompleteInformationsModal
        isOpen={
          isModalOpen &&
          currentUser?.publicationStatus !==
            PublicationStatuses.PublicationRequested
        }
        onClose={() => setModalOpen(false)}
      />
      {isBlurry && <Blur destroying={isDestroying} />}
    </>
  );
}
