import React from 'react';
import { t } from 'utils/translate';
import { InformationBannerTranslations } from './InformationBanner.translations';
import './InformationBanner.scss';

export default function InformationBanner() {
  return (
    <div className="information-banner">
      <div className="information-banner__text">
        {t(InformationBannerTranslations.text)}
      </div>
      <a
        href="https://help.myjobglasses.com/fr/collections/194722-nouveautes-produit"
        target="_blank"
        rel="noreferrer"
        className="information-banner__link"
      >
        {t(InformationBannerTranslations.link)}
      </a>
    </div>
  );
}
