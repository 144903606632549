import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query GET_SETTINGS($locale: AvailableLanguagesEnum) {
    settings {
      franceTravailAuthorizationUrl
      linkedinAuthorizationUrl
      primarySituations {
        key
        name(locale: $locale)
        secondarySituations {
          key
          name(locale: $locale)
        }
      }
      secondarySituations {
        key
        name(locale: $locale)
      }
    }
  }
`;
