import React from 'react';
import classNames from 'classnames';
import './Blur.scss';

type BlurProps = {
  destroying: boolean;
};

const Blur = ({ destroying }: BlurProps) => (
  <div
    className={classNames('switch-blur', {
      'switch-blur--destroy': destroying,
    })}
  />
);

export default Blur;
