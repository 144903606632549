import ActionCable from 'actioncable';
import { eventChannel } from 'redux-saga';
import { take, put } from 'redux-saga/effects';
import { closeConnexion, CONNEXION_START, CONNEXION_CLOSED } from '../actions/realtime';
import { sendError } from 'appsignal/appsignal';

// eslint-disable-next-line
const connexion = ActionCable.createConsumer(
  window.__RUNTIME_CONFIG__.REACT_APP_WEBSOCKET_HOST,
);

// export function* subscribe(channel, options = {}, params = {}) {
//   try {
//     // create channel
//     const socket = yield call(createSocketChannel, channel, options, params);
//     if (options.closeOnLocationChange) {
//       yield fork(autocloseSocket, socket);
//     }
//     while(true) { // eslint-disable-line no-constant-condition
//       const incomingEvent = yield take(socket);
//       if (typeof incomingEvent === 'string') {
//         yield put({
//           type: incomingEvent,
//         });
//       } else if (typeof incomingEvent === 'object' && incomingEvent.event_name) {
//         yield put(receiveData(incomingEvent.event_name, incomingEvent.payload));
//       }
//     }
//   } catch (e) {
//     sendError('Error on subscribe to a channel ' + channel, e);
//   }
// }

export function* subscribe() {}
/**
 * @param {Object} socket
 * @param {function} socket.close
 */
export function* autocloseSocket(socket) {
  try {
    yield take('@@router/LOCATION_CHANGE');
    yield put(closeConnexion());
    socket.close();
  } catch (e) {
    sendError('Error on autoclosing socket', e);
  }
}

/**
 * As explain on bottom of the below link
 * We need to create channel to handle socket connexion
 * @url https://redux-saga.js.org/docs/advanced/Channels.html#using-the-eventchannel-factory-to-connect-to-external-events
 * @param {String} channel - The back-end channel name on which subscribe
 * @param {Object} options
 * @param {Bool} closeOnLocationChange - Autoclosing connexions on location change
 * @param {Object} params - All params to send to back-end
 */
export const createSocketChannel = (channel, options, params) => {
  return eventChannel((emit) => {
    const subscriptions = connexion.subscriptions.create(
      {
        channel,
        ...params,
      },
      {
        connected: () => emit(CONNEXION_START),
        disconnected: () => emit(CONNEXION_CLOSED),
        received: (data) => emit(data),
      },
    );
    // return an unsubscription function which be called on .close()
    return () => connexion.subscriptions.remove(subscriptions);
  });
};
