/*
 * Bienvenue dans la décharge
 * Petit dej offert à celui qui réussira a refactor ce fichier...
 */
import every from 'lodash.every';
import filter from 'lodash.filter';
import find from 'lodash.find';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import set from 'lodash.set';
import { createSelector } from 'reselect';
import Routes from '../routes';

import { mapConversation } from '../utils/conversation';
import { clearObjectBlankValues } from '../utils/dev-tools';
import {
  flattenAttributes,
  findInverseRelationship,
  findAllInverseRelationship,
  findRelationship,
  findAllRelationships,
} from '../utils/json-api';
import apiSelectors, { profileContentEducationsSelector } from './api';
import { localStorageSelector } from './storable';
import { allSchoolCoachCollectionJsonApi } from './typed/profiles';
import { getCurrentUserData } from './current-user-data';

export const isFooterVisibleSelector = () => {
  return true;
  // WARN : visible everywhere except since minor cgu
  // return !matchPath(router?.location?.pathname, [
  //   Routes.conversations,
  //   Routes.conversation,
  //   Routes.downloadConversationAppointmentIcalendar,
  // ]);
};

const getViewName = (path) =>
  ({
    [Routes.conversation]: 'conversationView',
    [Routes.conversations]: 'conversationsView',
    [Routes.appointments]: 'appointmentsView',
    [Routes.professional]: 'profileView',
  })[path];

const getFlow = (pathname) =>
  ({
    [Routes.companyPage]: 'mentors',
  })[pathname] || 'students';

export const flowSelector = ({ router }) => getFlow(router?.location?.pathname);

export const isLoadingSelector = (state, props) =>
  props && props.match && props.match.path
    ? state[getViewName(props.match.path)]
      ? state[getViewName(props.match.path)].isLoading
      : false
    : null;

export const isLoadedSelector = (state, props) =>
  props && props.match && props.match.path
    ? state[getViewName(props.match.path)]
      ? state[getViewName(props.match.path)].isLoaded
      : false
    : null;

export const conversationIdSelector = ({ shared: { conversationId } }) =>
  conversationId;

export const usersSelector = (store) => store?.api?.users;

export const currentRouteSelector = ({ router }) =>
  router.location ? router.location.pathname : '/';

export const messagingConversationsSelector = ({ api }) =>
  api?.messagingConversations || [];

/**
 * Retrieve all appointments on the store
 * @param {Object} state
 */
export const messagingAppointmentsSelector = ({
  api: { messagingAppointments },
}) => messagingAppointments || [];

/**
 * Retrieve all mentors profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileMentorsSelector = ({ api: { profileMentors } }) =>
  (profileMentors && profileMentors.map((p) => ({ ...p, type: 'mentor' }))) ||
  [];

/**
 * Retrieve all employee profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileEmployeesSelector = ({ api: { profileEmployees } }) =>
  (profileEmployees &&
    profileEmployees.map((p) => ({ ...p, type: 'employee' }))) ||
  [];

/**
 * Retrieve all vips profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileVIPsSelector = ({ api: { profileVips } }) =>
  (profileVips && profileVips.map((p) => ({ ...p, type: 'vip' }))) || [];

/**
 * Retrieve all students profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileStudentsSelector = ({ api: { profileStudents } }) =>
  (profileStudents &&
    profileStudents.map((p) => ({ ...p, type: 'student' }))) ||
  [];

/**
 * Retrieve all moderators profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileModeratorSelector = ({ api: { profileModerators } }) =>
  (profileModerators &&
    profileModerators.map((p) => ({ ...p, type: 'moderator' }))) ||
  [];

/**
 * Retrieve all mjgAdmins profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileMJGAdminSelector = ({ api: { profileMjgAdmins } }) =>
  (profileMjgAdmins &&
    profileMjgAdmins.map((p) => ({ ...p, type: 'MJGAdmin' }))) ||
  [];

/**
 * Retrieve all mjgAdmins profiles on the store
 * @param {Object} store
 * @moveto selectors/profile
 */
export const profileCompanyAdminSelector = ({
  api: { profileCompanyAdmins },
}) =>
  (profileCompanyAdmins &&
    profileCompanyAdmins.map((p) => ({ ...p, type: 'companyAdmin' }))) ||
  [];

/**
 * @param {Object} store
 * @moveto selectors/profile
 */
export const allProfilesSelector = createSelector(
  profileMentorsSelector,
  profileEmployeesSelector,
  profileVIPsSelector,
  profileStudentsSelector,
  profileMJGAdminSelector,
  profileModeratorSelector,
  profileCompanyAdminSelector,
  allSchoolCoachCollectionJsonApi,
  (
    mentors,
    employees,
    vips,
    students,
    mjgAdmins,
    moderators,
    companyAdmins,
    schoolCoach,
  ) => [
    // add company admin here when them passed to new site
    ...employees,
    ...mentors,
    ...students,
    ...moderators,
    ...mjgAdmins,
    ...vips,
    ...companyAdmins,
    ...schoolCoach,
  ],
);

export const currentUserSelector = createSelector(
  getCurrentUserData('email'),
  usersSelector,
  (email, users) => {
    if (users?.length && email) {
      return find(users, (u) => u.attributes.email === email);
    }
  },
);

/**
 * Return all promotions from the store
 * @param {Object} state - redux store
 */
export const profilePromotionsSelector = ({
  api: { profileContentPromotions },
}) => profileContentPromotions;

/**
 * Return all hibernations from the store
 * @param {Object} state - redux store
 */
export const profileHibernationsJsonApiFromState = ({
  api: { hibernations },
}) => hibernations;

/**
 * Get identities from store
 * @param {Object} state - redux store
 */
export const identitiesJsonApiFromState = ({ api: { identities } }) =>
  identities;

/**
 *
 * @param {Object} store
 */
export const internshipsSelector = ({ api: { profileInternships } }) =>
  profileInternships || [];

/**
 * Current user selector + mapper
 * @moveto saga/user
 */
export const userSelector = createSelector(
  getCurrentUserData('email'),
  usersSelector,
  allProfilesSelector,
  profileContentEducationsSelector,
  profilePromotionsSelector,
  profileHibernationsJsonApiFromState,
  identitiesJsonApiFromState,
  internshipsSelector,
  (
    email,
    users,
    profiles,
    educations,
    profilePromotions,
    profileHibernations,
    identities,
    internships,
  ) => {
    if (users?.length && email) {
      const user = find(users, (u) => u.attributes.email === email);

      if (user) {
        // We need a main profile for compatibility
        // So we need to retrieve main profile in order : Employee, mentor, student, moderator, other
        // (as ordered of usability on the front side)
        // @deprecated : https://github.com/Startouf/MyJobGlasses/issues/3317
        const profile = find(
          profiles,
          (p) => p?.relationships?.user?.data?.id === user.id,
        );
        // unpublisher profile part because put it in lower layer is nearly impossible
        // 🙏 don't blame me, I have create a chore to fix this shit
        // @todo https://github.com/MyJobGlasses/Hermes/issues/3698
        let unpublisherUser;
        if (isValidRelationships(profile, ['lastUnpublisherProfile'])) {
          const unpublisherProfileRaw = findRelationship(
            profile,
            profiles,
            'lastUnpublisherProfile',
          );
          if (unpublisherProfileRaw) {
            if (isValidRelationships(unpublisherProfileRaw, ['user'])) {
              const unpublisherUserRaw = findRelationship(
                unpublisherProfileRaw,
                users,
                'user',
              );
              unpublisherUser = mapUser(unpublisherUserRaw);
            }
          }
        }
        // Now we retrieve all profile to handle user with multi-roles
        const filteredProfiles = filter(
          profiles,
          (p) => p?.relationships?.user?.data?.id === user.id,
        );
        const userIdentities = findAllRelationships(
          user,
          identities,
          'identities',
        );

        return mapUserWithProfile(
          user,
          filteredProfiles,
          profile && profile.type,
          educations,
          profilePromotions,
          profileHibernations,
          unpublisherUser,
          userIdentities,
          internships,
        );
      }
    }
  },
);

/**
 * Retrieve draft for mentor profiles
 * @param {Object} state
 * @return {Array}
 */
export const mentorProfileDraftSelector = ({ api: { profileMentorDrafts } }) =>
  profileMentorDrafts || [];

/**
 * Retrieve draft for employee profiles
 * @param {Object} state
 * @return {Array}
 */
export const employeeProfileDraftSelector = ({
  api: { profileEmployeeDrafts },
}) => profileEmployeeDrafts || [];

/**
 * Retrieve all profiles
 * @param {Object} state
 */
export const profileDraftSelector = createSelector(
  mentorProfileDraftSelector,
  employeeProfileDraftSelector,
  (profileMentorDrafts, profileEmployeeDrafts) => [
    ...profileMentorDrafts,
    ...profileEmployeeDrafts,
  ],
);

/**
 * Return the current logged user with draft data
 * @moveto saga/profile
 */
export const currentUserWithDraftModeSelector = createSelector(
  userSelector,
  allProfilesSelector,
  profileDraftSelector,
  localStorageSelector('savedDescriptionParts'),
  (user, profiles, drafts, locallySavedDescriptionParts) => {
    if (!user) {
      return;
    }
    user.profiles = (user?.profiles || []).map((profile) => {
      const draft = profile?.draft;
      if (draft) {
        profile = Object.assign({}, profile, clearObjectBlankValues(draft));
      }
      if (locallySavedDescriptionParts) {
        set(profile, 'descriptionParts', {
          ...(profile?.descriptionParts || {}),
          ...locallySavedDescriptionParts,
        });
      }
      return profile;
    });
    const profile = findInverseRelationship(user, profiles, 'user');
    const draft = findRelationship(profile, drafts, 'profileDraft');
    if (draft) {
      user.profile = Object.assign(
        {},
        user.profile,
        clearObjectBlankValues(draft.attributes),
      );
    }
    if (locallySavedDescriptionParts) {
      set(user, 'profile.descriptionParts', {
        ...(profile?.profile?.descriptionParts || {}),
        ...locallySavedDescriptionParts,
      });
    }
    return user;
  },
);

export const apiAppointmentSelector = (
  { api: { messagingAppointments } },
  { id },
) => find(messagingAppointments, { id });

export const appointmentSelector = createSelector(
  apiAppointmentSelector,
  messagingConversationsSelector,
  usersSelector,
  allProfilesSelector,
  (appointment, conversations, users, profiles) =>
    appointment &&
    mapAppointment(appointment, conversations, [], users, profiles),
);

/**
 *
 * @param {Object} param
 * @param {*} props
 */
export const conversationSelector = (
  { api: { messagingConversations } },
  props,
) => find(messagingConversations, { id: props.match.params.id });

/**
 * Return a clean user object (without sub-key attributes)
 * @param {Object} [user]
 * @return {Object} - Flattened user or blank object
 */
const mapUser = (user) => {
  if (user) {
    return flattenAttributes(user);
  }
  return {};
};

/**
 * @moveto utils/user
 * @param {Object} user - User to map
 * @param {Array} profiles - All existing profiles
 * @param {String} type - main profile type
 * @param {Array} educations - All existing educations
 * @param {Array} profilePromotions - All existing promotions
 * @param {Array} companySectors - All existing sectors
 */
export const mapUserWithProfile = (
  user,
  profiles,
  type,
  educations,
  profilePromotions,
  profileHibernations,
  unpublisherUser,
  userIdentities,
  internships,
) => ({
  ...mapUser(user),
  type,
  profiles: user
    ? map(
        filter(
          profiles,
          (profile) => profile?.relationships?.user?.data?.id === user?.id,
        ),
        (profile) =>
          mapProfile(
            profile,
            educations,
            profilePromotions,
            profileHibernations,
            unpublisherUser,
            internships,
          ),
      )
    : [],
  identities: userIdentities
    ? userIdentities.map((identity) => flattenAttributes(identity))
    : null,
  // @deprecated: https://github.com/Startouf/MyJobGlasses/issues/3317
  profile: user
    ? mapProfile(
        find(profiles, (p) => p?.relationships?.user?.data?.id === user?.id),
        educations,
        profilePromotions,
        profileHibernations,
        unpublisherUser,
      )
    : {},
});

const sanitizeProfile = (profile) => ({
  ...profile,
  coordinates: profile.coordinates ? profile.coordinates : [],
});

/**
 * @moveto utils/profile
 * @param {Object} profile - profile to map
 * @param {Array} educations - all existing educations
 * @param {Array} profileContentPromotions - all existing promotions
 */
const mapProfile = (
  profile,
  educations,
  profileContentPromotions,
  profileHibernations,
  unpublisherUser,
  internships,
) =>
  profile && {
    ...sanitizeProfile(flattenAttributes(profile)),
    type: profile.type,
    unpublisherUser,
    educations: (() => {
      if (isValidRelationships(profile, ['educations'])) {
        const profileEducations = findAllRelationships(
          profile,
          educations,
          'educations',
        );

        if (profileEducations) {
          return map(profileEducations, (e) => flattenAttributes(e));
        }
      }

      return null;
    })(),
    promotions: (() => {
      const profilePromotionsIds = map(
        profile?.relationships?.promotions?.data || [],
        'id',
      );
      let profilePromotions = filter(
        profileContentPromotions,
        (tag) => profilePromotionsIds.indexOf(tag.id) !== -1,
      );
      return map(profilePromotions, (promotion) =>
        flattenAttributes(promotion),
      );
    })(),
    hibernations: (() => {
      let hibernationsFromProfile = [];
      if (isValidRelationships(profile, ['hibernations'])) {
        hibernationsFromProfile = findAllRelationships(
          profile,
          profileHibernations,
          'hibernations',
        );
      }
      let hibernationWithProfile = findAllInverseRelationship(
        profile,
        profileHibernations,
        'professional',
      );
      const hibernations = [
        ...hibernationsFromProfile,
        ...hibernationWithProfile,
      ];
      return hibernations
        ? hibernations.map((hibernation) => flattenAttributes(hibernation))
        : null;
    })(),
    internships: (() => {
      if (isValidRelationships(profile, ['internships'])) {
        const profileInternships = findAllRelationships(
          profile,
          internships,
          'internships',
        );
        if (profileInternships) {
          return map(profileInternships, (e) => flattenAttributes(e));
        }
      }

      return null;
    })(),
  };

export const messageMapper = ({ message, users, profileMentors }) => ({
  ...flattenAttributes(message),
  sender:
    isValidRelationships(message, ['sender']) &&
    mapUserWithProfile(
      findRelationship(message, users, 'sender'),
      profileMentors,
    ),
});

export const mapAppointment = (appointment, conversations, ...args) => ({
  ...flattenAttributes(appointment),
  conversation:
    isValidRelationships(appointment, ['conversation']) &&
    mapConversation(
      findRelationship(appointment, conversations, 'conversation'),
      ...args,
    ),
});

export const isValidRelationships = (resource, attributes) =>
  resource
    ? every(
        attributes,
        (attribute) => !isEmpty(resource.relationships[attribute]),
      )
    : false;

/**
 * @deprecated - Please use directly isProfessional(user) from utils
 */
export const isProfessionalSelector = createSelector(
  userSelector,
  profileMentorsSelector,
  profileEmployeesSelector,
  (user, profileMentors, profileEmployees) =>
    user &&
    !!find(
      [...profileMentors, ...profileEmployees],
      (p) => p?.relationships?.user?.data?.id === user?.id,
    ),
);

export const mapConversationWithProfileMentors = (
  conversation,
  profileMentors,
  users,
) => ({
  ...flattenAttributes(conversation),
  recipient: mapUserWithProfile(
    find(users, { id: conversation.relationships.recipient.data.id }),
    profileMentors,
  ),
});

export const isRecommendationsSelector = ({ router }) =>
  router?.location?.state?.isRecommendations || false;
export const modalSelector = ({ modals }, key) => modals?.[key];

export default {
  ...apiSelectors,
};
