import ApiResource from '../api-resources';
import {
  createResource,
  deleteResource,
  readResource,
  readListResource,
  updateResource,
} from './api';

export const CREATE_APPOINTMENT = '@@appointments/CREATE';
export const UPDATE_APPOINTMENT = '@@appointments/UPDATE';
export const CLAIM_APPOINTMENT = '@@appointments/CLAIM';
export const APPROVE_APPOIMENT_CLAIM = '@@appointments/APPROVE_APPOIMENT_CLAIM';
export const REJECT_APPOIMENT_CLAIM = '@@appointments/REJECT_APPOIMENT_CLAIM';

export const createAppointment = (values, resolve, reject) => ({
  type: CREATE_APPOINTMENT,
  values,
  resolve,
  reject,
});

export const updateAppointment = (id, values, resolve, reject) => ({
  type: UPDATE_APPOINTMENT,
  id,
  values,
  resolve,
  reject,
});

/**
 * Claim an appointment to a professional
 * @param {String} conversationId
 * @param {Object} values
 * @param {Promise.resolve} resolve
 * @param {Promise.reject} reject
 */
export const claimAppointment = (conversationId, values, resolve, reject) => ({
  type: CLAIM_APPOINTMENT,
  conversationId,
  values,
  resolve,
  reject,
});

/**
 * Action to inform of user approve choice
 */
export const approveAppointmentClaim = () => ({
  type: APPROVE_APPOIMENT_CLAIM,
});

/**
 * Action to inform of user reject choice
 */
export const rejectAppointmentClaim = (reason) => ({
  type: REJECT_APPOIMENT_CLAIM,
  reason,
});

/**
 * Approve an appointment claim (on the server)
 * @param {String} appointmentClaimId
 */
export const approveAppointmentClaimResource = (appointmentClaimId) =>
  updateResource({
    resourceUri: `/messaging/appointment_claims/${appointmentClaimId}/approve`,
    data: {
      id: appointmentClaimId,
      type: ApiResource.MESSAGING_APPOINTMENT_CLAIM_APPROVE,
      attributes: {},
    },
  });

/**
 * Reject an appointment claim (on the server)
 * @param {String} appointmentClaimId
 * @param {String} reason
 */
export const rejectAppointmentClaimResource = (appointmentClaimId, reason) =>
  updateResource({
    resourceUri: `/messaging/appointment_claims/${appointmentClaimId}/deny`,
    data: {
      id: appointmentClaimId,
      type: ApiResource.MESSAGING_APPOINTMENT_CLAIM_DENY,
      attributes: {
        reason,
      },
    },
  });

/**
 * Claim an appointment to a professional on the server
 * @param {Object} params
 * @param {String} params.conversationId - ID of conversation concerned by the new appointment
 * @param {Moment} params.from - The moment object of start appointment
 * @param {String} params.type - Type of the appointment (eg: physical, video, call)
 * @param {String} [params.meeting_place] - Address of the appointment
 * @param {Object} [params.position] - Coordinates of the appointment
 * @param {Float}  [params.position.lat]
 * @param {Float}  [params.position.lng]
 */
export const claimAppointmentResource = ({
  conversationId,
  from,
  to,
  type,
  meeting_place,
  position,
}) =>
  createResource({
    resourceUri: `/messaging/conversations/${conversationId}/appointment_claims`,
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT_CLAIM,
      attributes: {
        from,
        to,
        type,
        time_zone: 'Europe/Paris',
        meeting_place,
        coordinates: [position.lat, position.lng],
      },
    },
  });

/**
 * Create an appointment
 * @param {Object} params
 * @param {String} params.conversationId - ID of conversation concerned by the new appointment
 * @param {Moment} params.from - The moment object of start appointment
 * @param {String} params.type - Type of the appointment (eg: physical, video, call)
 * @param {String} [params.meeting_place] - Address of the appointment
 * @param {Object} [params.position] - Coordinates of the appointment
 * @param {Float}  [params.position.lat]
 * @param {Float}  [params.position.lng]
 */
export const createAppointmentResource = ({
  conversationId,
  from,
  type,
  meeting_place,
  position,
}) =>
  createResource({
    resourceUri: `/messaging/conversations/${conversationId}/appointments`,
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
      attributes: {
        from: from.format('YYYY-MM-DD HH:mm:00 ZZ'),
        to: from.add(1, 'h').format('YYYY-MM-DD HH:mm:00 ZZ'),
        type,
        time_zone: 'Europe/Paris',
        meeting_place,
        coordinates: [position.lat, position.lng],
      },
    },
  });

/**
 * Update an appointment
 * @param {Object} params
 * @param {String} params.id - ID of the appointment to update
 * @param {Moment} params.from - The moment object of start appointment
 * @param {String} params.type - Type of the appointment (eg: physical, video, call)
 * @param {String} [params.meeting_place] - Address of the appointment
 * @param {Object} [params.position] - Coordinates of the appointment
 * @param {Float}  [params.position.lat]
 * @param {Float}  [params.position.lng]
 */
export const updateAppointmentResource = ({
  id,
  from,
  type,
  meeting_place,
  position,
}) =>
  updateResource({
    resourceUri: `/messaging/appointments/${id}`,
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
      attributes: {
        from: from.format('YYYY-MM-DD HH:mm:00 ZZ'),
        to: from.add(1, 'h').format('YYYY-MM-DD HH:mm:00 ZZ'),
        type: type,
        time_zone: 'Europe/Paris',
        meeting_place: meeting_place,
        coordinates: [position.lat, position.lng],
      },
    },
  });

/**
 * Retrieve appointment from api
 * @param {Object} [params]
 * @param {bool} [params.includeCancelled] - request (or not) appointment cancelled from api
 */
export const getAppointmentsList = (params) =>
  readListResource({
    resourceUri: '/messaging/appointments',
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
    },
    params: {
      include: [
        'conversation.last_sender',
        'conversation.messages.sender',
        'conversation.initiator',
        'conversation.initiator_profile.educations',
        'conversation.initiator_profile.user',
        'conversation.recipient',
        'conversation.recipient_profile.organization',
        'conversation.recipient_profile.user',
      ].join(','),
      ...(params && params.includeCancelled
        ? {}
        : { 'filter[visible]': 'true' }),
    },
  });

/**
 * Retrieve a single appointment
 * @param {Object} params
 * @param {String} params.id - appointment id
 */
export const getAppointment = ({ id }) =>
  readResource({
    resourceUri: `/messaging/appointments/${id}`,
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
    },
    params: {
      include: [
        'conversation',
        'conversation.recipient',
        'conversation.recipient_profile.educations',
        'conversation.recipient_profile.user',
        'conversation.initiator',
        'conversation.initiator_profile.organization',
        'conversation.initiator_profile.user',
      ].join(','),
    },
  });

/**
 * Retrieve upcoming appointment
 * @param {Object} params
 * @param {String} params.id - appointment id
 */
export const getUpcomingAppointment = () =>
  readResource({
    resourceUri: '/messaging/appointments',
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
    },
    params: {
      include: [
        'conversation',
        'conversation.recipient',
        'conversation.recipient_profile.educations',
        'conversation.recipient_profile.user',
        'conversation.initiator',
        'conversation.initiator_profile.organization',
        'conversation.initiator_profile.user',
      ].join(','),
      ...{ 'filter[upcoming]': 'true' },
    },
  });

export const deleteAppointment = ({ id }, resolve, reject) =>
  deleteResource(
    {
      resourceUri: `/messaging/appointments/${id}`,
      data: {
        type: ApiResource.MESSAGING_APPOINTMENT,
      },
    },
    resolve,
    reject,
  );
