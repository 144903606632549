import PropTypes from 'prop-types';
import { branch, compose, renderComponent } from 'recompose';
import MainMenuItemCompany from './components/main-menu-item-company/MainMenuItemCompany';
import MainMenuItemLink from './components/main-menu-item-link/MainMenuItemLink';
import MainMenuItemScore from './components/main-menu-item-score/MainMenuItemScore';

const MainMenuItem = compose(
  branch(({ type }) => type === 'score', renderComponent(MainMenuItemScore)),
  branch(
    ({ type }) => type === 'company-link',
    renderComponent(MainMenuItemCompany),
  ),
)(MainMenuItemLink);

MainMenuItem.propTypes = {
  type: PropTypes.oneOf(['link', 'score', 'company-link']),
};
MainMenuItem.defaultProps = {
  type: 'link',
};

export default MainMenuItem;
