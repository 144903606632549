import PropTypes from 'prop-types';
import { branch, compose, renderComponent } from 'recompose';
import UsernavItemAction from './components/usernav-item-action/UsernavItemAction';
import UsernavItemLink from './components/usernav-item-link/UsernavItemLink';
import UsernavItemCompanyLink from './components/usernav-item-company-link/UsernavItemCompanyLink';
import UsernavItemExternalLink from './components/usernav-item-external-link/UsernavItemExternalLink';
import UsernavItemSwitchAccount from './components/usernav-item-switch-account/UsernavItemSwitchAccount';
import UsernavItemSwitchExternalAccount from './components/usernav-item-switch-account/UsernavItemSwitchExternalAccount';

const UsernavItem = compose(
  branch(({ type }) => type === 'action', renderComponent(UsernavItemAction)),
  branch(
    ({ type }) => type === 'switch-account',
    renderComponent(UsernavItemSwitchAccount),
  ),
  branch(
    ({ type }) => type === 'external-link',
    renderComponent(UsernavItemExternalLink),
  ),
  branch(
    ({ type }) => type === 'external-switch',
    renderComponent(UsernavItemSwitchExternalAccount),
  ),
  branch(
    ({ type }) => type === 'company-link',
    renderComponent(UsernavItemCompanyLink),
  ),
)(UsernavItemLink);

UsernavItem.propTypes = {
  type: PropTypes.oneOf([
    'action',
    'link',
    'switch-account',
    'external-link',
    'company-link',
  ]),
  className: PropTypes.string,
};
UsernavItem.defaultProps = {
  type: 'link',
  className: undefined,
};

export default UsernavItem;
