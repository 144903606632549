import React from 'react';

const DashboardIcon = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
  >
    <g className="hover">
      <path
        fill="#e0ca4b"
        d="M6.5,10.5h-2c-0.5,0-1,0.5-1,1v8c0,0.5,0.5,1,1,1h2c0.5,0,1-0.5,1-1v-8C7.5,11,7,10.5,6.5,10.5z"
      />
      <path
        fill="#e0ca4b"
        d="M12.5,0.5h-2c-0.5,0-1,0.5-1,1v18c0,0.5,0.5,1,1,1h2c0.5,0,1-0.5,1-1v-18C13.5,1,13,0.5,12.5,0.5z"
      />
      <path
        fill="#e0ca4b"
        d="M18.5,4.5h-2c-0.5,0-1,0.5-1,1v14c0,0.5,0.5,1,1,1h2c0.5,0,1-0.5,1-1v-14C19.5,5,19,4.5,18.5,4.5z"
      />
    </g>
    <g>
      <path
        fill="#0E0E11"
        d="M4.5,21h2C7.3,21,8,20.3,8,19.5v-8C8,10.7,7.3,10,6.5,10h-2C3.7,10,3,10.7,3,11.5v8C3,20.3,3.7,21,4.5,21zM4,11.5C4,11.2,4.2,11,4.5,11h2C6.8,11,7,11.2,7,11.5v8C7,19.8,6.8,20,6.5,20h-2C4.2,20,4,19.8,4,19.5V11.5z"
      />
      <path
        fill="#0E0E11"
        d="M10.5,21h2c0.8,0,1.5-0.7,1.5-1.5v-18C14,0.7,13.3,0,12.5,0h-2C9.7,0,9,0.7,9,1.5v18C9,20.3,9.7,21,10.5,21z M10,1.5C10,1.2,10.2,1,10.5,1h2C12.8,1,13,1.2,13,1.5v18c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5V1.5z"
      />
      <path
        fill="#0E0E11"
        d="M16.5,21h2c0.8,0,1.5-0.7,1.5-1.5v-14C20,4.7,19.3,4,18.5,4h-2C15.7,4,15,4.7,15,5.5v14C15,20.3,15.7,21,16.5,21z M16,5.5C16,5.2,16.2,5,16.5,5h2C18.8,5,19,5.2,19,5.5v14c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5V5.5z"
      />
      <path
        fill="#0E0E11"
        d="M21,23H3.5C3.2,23,3,23.2,3,23.5S3.2,24,3.5,24H21c0.3,0,0.5-0.2,0.5-0.5S21.3,23,21,23z"
      />
    </g>
  </svg>
);

export default DashboardIcon;
