import find from 'lodash.find';
import map from 'lodash.map';
import memoize from 'lodash.memoize';
import { getFormSyncErrors } from 'redux-form';
import { createSelector } from 'reselect';
import { mapConversation } from '../utils/conversation';
import { profileContentEducationsSelector } from './api';
import { notCancelledAppointmentsSelector } from './appointments';
import { studentInternshipsJsonApiFromState } from './profile';
import {
  usersSelector,
  allProfilesSelector,
  messagingAppointmentsSelector,
  isValidRelationships,
  messagingConversationsSelector,
} from '.';

/**
 * Get all conversations IDs to display (stored in the index)
 * @param {Object} state - redux store
 * @param {Object} state.apiIndexes - store entry for index
 */
export const getConversationIndex = ({ apiIndexes }) =>
  apiIndexes?.conversations?.results || [];

/**
 * Get not replied conversations IDs
 * @param {Object} state - redux store
 * @param {Object} state.apiIndexes - story entry for index
 */
export const getNotRepliedConversationIndexFromState = ({ apiIndexes }) =>
  apiIndexes?.conversationsNotReplied?.results || [];

/**
 * Return the list of all stored conversations
 * @param {Object} state - redux store
 * @param {Object} state.api - all api data
 */
export const getConversations = ({ api }) => api?.messagingConversations || [];

/**
 * Retrieve not replied conversations from index
 * @param {Object} state - redux store
 * @param {Object} state.api - all api data
 * @param {Object} state.apiIndexes - store entry for index
 * @returns {Array} - list of conversations
 */
export const getNotRepliedConversationsFromIndex = createSelector(
  getNotRepliedConversationIndexFromState,
  getConversations,
  (ids, conversations) => {
    if (ids.length === 0 && conversations.length === 0) {
      return [];
    }
    return map(ids, (id) => find(conversations, { id }));
  },
);

/**
 * Retrieve all appointment claims from redux state
 * @param {Object} state
 * @returns {Array}
 */
export const appointmentClaimsFromState = ({ api }) =>
  api?.messagingAppointmentClaims || [];

/**
 * Retrieve conversations from an index
 * @param {Object} state - redux store
 * @returns {Array} - list of conversations
 */
export const getNotRepliedConversationsFromIndexSelector = createSelector(
  getNotRepliedConversationsFromIndex,
  usersSelector,
  allProfilesSelector,
  messagingAppointmentsSelector,
  (conversations, ...relations) =>
    map(conversations, (conversation) =>
      mapConversation(conversation, [], ...relations),
    ),
);

/**
 * Get the number of not replied conversations
 * @param {Object} state - redux store
 * @returns {Integer} - number of not replied conversation items
 */
export const countOfnotRepliedConversation = ({ apiIndexes }) =>
  apiIndexes?.conversationsNotReplied?.page?.total || null;

/**
 * Retrieve all conversations from state
 * @param {Object} state - redux store
 */
export const allConversationsJsonApiFromState = ({ api }) =>
  api?.messagingConversations || [];

/**
 * Retrieve a specific conversation with linked user
 * @param {String} id - ID of the conversion
 * @returns {Function}
 */
export const conversationFromAppointmentIDSelector = memoize((id) =>
  /**
   * @param {Object} state - redux store
   */
  createSelector(
    allConversationsJsonApiFromState,
    notCancelledAppointmentsSelector,
    usersSelector,
    (conversations, appointments, users) => {
      const appointment = find(appointments, { id });
      if (!appointment) {
        return;
      }
      const conversationID = appointment?.conversation?.id;
      if (!conversationID) {
        return;
      }
      const conversation = find(conversations, { id: conversationID });
      if (!conversation) {
        return;
      }
      return mapConversation(conversation, [], users);
    },
  ),
);

const conversationCreationFormErrorsSelector = getFormSyncErrors(
  'conversation-initiate',
);

/**
 * Retrieve avatar progress state object to display circular progress
 * @param {Object} state - redux form
 */
export const conversationInitiateProgressStepsSelector = createSelector(
  conversationCreationFormErrorsSelector,
  (errors) => {
    return ['presentation', 'explanation', 'pitch', 'valediction'].reduce(
      (acc, v) => {
        if (acc?.[acc.length - 1]?.color === '#a94442') {
          return acc;
        }
        if (errors && errors[v]) {
          acc = [...acc, { percent: 25, color: '#a94442' }];
          return acc;
        }
        acc = [...acc, { percent: 25, color: '#09193b' }];
        return acc;
      },
      [],
    );
  },
);

/**
 * Retrieve a selector to select one conversation
 * @param {String} id
 */
export const conversationWithMessagesSelector = memoize((id) =>
  /**
   * Retrieve a conversation from state
   * @param {Object} state
   */
  createSelector(
    messagingConversationsSelector,
    usersSelector,
    allProfilesSelector,
    messagingAppointmentsSelector,
    appointmentClaimsFromState,
    profileContentEducationsSelector,
    studentInternshipsJsonApiFromState,
    (
      conversations = [],
      users,
      profiles,
      appointments,
      appointmentClaims,
      profileContentEducations,
      profileInternships,
    ) => {
      const conversation = conversations.find(
        ({ id: conversationId }) => conversationId === id,
      );
      if (!conversation) {
        return null;
      }
      if (
        isValidRelationships(conversation, [
          'initiator',
          'initiatorProfile',
          'recipient',
          'recipientProfile',
        ])
      ) {
        return mapConversation(
          conversation,
          [],
          users,
          profiles,
          appointments,
          appointmentClaims,
          profileContentEducations,
          profileInternships,
        );
      }
      return null;
    },
  ),
);

export const conversationFromJsonApiSelector = conversationWithMessagesSelector;
export const conversationWithMessagesWithCommitedSelector =
  conversationWithMessagesSelector;
