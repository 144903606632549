import { useQuery } from '@apollo/client';
import { PageLoader } from 'components/loader/Loader';
import { redirectToAccount } from 'hoc/protect-route/protectRoute';
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import Routes from 'routes';
import { userSelector } from 'selectors';
import { getAccountUrl } from 'utils/redirection.util';
import {
  CompletionContextEnum,
  GetCurrentUserContextQuery,
  GetCurrentUserContextQueryVariables,
} from '../../@types/graphql.d';
import { CURRENT_USER_CONTEXT_QUERY } from './CurrentUserContext.gql';
import {
  CurrentUser,
  CurrentUserContextDef,
  SwitchState,
} from './CurrentUserContext.types';
import {
  LS_SWITCH_STATE_KEY,
  getSwitchStateBasedOnSituation,
  updateSwitchStateLocalStorage,
} from './CurrentUserContext.utils';

export const LS_WELCOME_MODAL_KEY = 'show_welcome_modal';

const INITIAL_CONTEXT_VALUES: CurrentUserContextDef = {
  currentUser: null,
  switchState:
    (localStorage.getItem(LS_SWITCH_STATE_KEY) as SwitchState) || 'member',
};

export const CurrentUserContext = createContext<CurrentUserContextDef>(
  INITIAL_CONTEXT_VALUES
);

export const CurrentUserContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const jsonApiCurrentUser = useSelector(userSelector);
  const { boot: bootIntercom } = useIntercom();

  // eslint-disable-next-line
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(
    INITIAL_CONTEXT_VALUES.currentUser
  );
  const [switchState, setSwitchState] = useState<SwitchState>(
    INITIAL_CONTEXT_VALUES.switchState
  );
  useEffect(() => {
    if (currentUser) {
      updateSwitchStateLocalStorage(switchState, currentUser);
    }
  }, [switchState, currentUser]);

  useQuery<GetCurrentUserContextQuery, GetCurrentUserContextQueryVariables>(
    CURRENT_USER_CONTEXT_QUERY,
    {
      variables: {
        completionContext: CompletionContextEnum.StepByStep,
      },
      onCompleted: (data) => {
        if (data?.currentUserV2 === null) redirectToAccount();
        if (data?.currentUserV2) {
          setCurrentUser({ ...data.currentUserV2 });
          setSwitchState(getSwitchStateBasedOnSituation(data.currentUserV2));
        }
      },
      onError: (error) => {
        if (error?.message === 'Not found') redirectToAccount();
      },
    }
  );

  useEffect(() => {
    if (!jsonApiCurrentUser) {
      return;
    }

    bootIntercom({
      userId: jsonApiCurrentUser.id,
      name: `${jsonApiCurrentUser.firstName || ''} ${
        jsonApiCurrentUser.lastName || ''
      }`,
      email: jsonApiCurrentUser.email,
      userHash: jsonApiCurrentUser.intercomHash || undefined,
      languageOverride: jsonApiCurrentUser.emailLocale || undefined,
    });
  }, [jsonApiCurrentUser]);

  if (!currentUser) {
    return <PageLoader />;
  }

  if (shouldRedirectToAccount(currentUser.completionFor?.complete)) {
    window.location.href = getAccountUrl('/');
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        switchState,
        setSwitchState,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

function shouldRedirectToAccount(complete?: boolean) {
  return !complete && !isCurrentlyLocatedOnCompanyAdminRoute();
}

function isCurrentlyLocatedOnCompanyAdminRoute() {
  return window.location.href.includes(Routes.companyAdminBase);
}
