import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import { generatePath, matchPath } from 'react-router';
import { eventChannel } from 'redux-saga';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import {
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  CREATE_APPOINTMENT,
  CLAIM_APPOINTMENT,
  UPDATE_APPOINTMENT,
  createAppointmentResource,
  updateAppointmentResource,
  claimAppointmentResource,
  getAppointment,
  getAppointmentsList,
} from '../actions/appointments';
import { setContainerLoadState } from '../actions/container-load-state';
import { getConversation } from '../actions/conversations';
import { displayToastr } from '../actions/toastr';
import { getProfile } from '../actions/profile';
import { ownPendingAppointmentReviewTasksModelSelector } from '../selectors/messaging';
import {
  getCreateSuccessActionType,
  getUpdateSuccessActionType,
  getCreateErrorActionType,
  getUpdateErrorActionType,
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';
import { getPosition } from '../services/Position';
import { t } from '../utils/translate';
import {
  extractReadableErrorMessageFromErrorPayload,
  extractErrorsFromPayload,
} from '../utils/json-api';
import Routes from '../routes';
import { currentRouterPathnameSelector } from '../selectors/router';
import { sendError } from 'appsignal/appsignal';

export const appointmentSagaTranslations = defineMessages({
  successAppointmentCreated: {
    id: 'toastr.appointments.successAppointmentCreated',
    defaultMessage: 'Félicitations, votre rendez-vous a bien été créé !',
    description: 'Messages lors des création de RDV',
  },
  successAppointmentCreatedInThePast: {
    id: 'toastr.appointments.successAppointmentCreatedInThePast',
    defaultMessage: "Vous venez d'ajouter un RDV dans le passé",
    description: 'Messages lors des création de RDV',
  },
  successUpdateAppointment: {
    id: 'toastr.appointments.successUpdateAppointment',
    defaultMessage: 'Votre rendez-vous a été modifié',
    description: 'Messages lors des création de RDV',
  },
  errorAppointmentCreate: {
    id: 'toastr.appointments.errorAppointmentCreate',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
    description: 'Messages lors des création de RDV',
  },
  confirmCreateAppointmentInThePast: {
    id: 'toastr.appointments.confirmCreateAppointmentInThePast',
    defaultMessage: 'Etes vous sûr de vouloir définir un RDV dans le passé ?',
    description: 'Messages lors des création de RDV dans le passé',
  },
  studentSoftDeleted: {
    id: 'toastr.appointments.studentSoftDeleted',
    defaultMessage: 'Ce Membre a supprimé son compte',
    description: 'Messages lors des création de RDV dans le passé',
  },
  successClaimToastr: {
    id: 'toastr.appointments.successClaimToastr',
    defaultMessage:
      'Votre demande a été prise en compte. Nous attendons la validation du professionnel pour confirmer votre rendez-vous.',
    description: 'Toastr de succes de la création de requête de RDV',
  },
  errorClaimToastr: {
    id: 'toastr.appointments.errorClaimToastr',
    defaultMessage: 'Désolé, une erreur est survenue. Essaie à nouveau.',
    description: 'Toastr de succes de la création de requête de RDV',
  },
  toastrAppointmentClaimAppointmentDenySuccess: {
    id: 'toastr.appointments.claim.denySuccess',
    defaultMessage: 'Merci, votre réponse a été enregistrée.',
    description: 'Erreur lorsque le RDV existe déjà',
  },
  toastrAppointmentClaimAlreadyApproved: {
    id: 'toastr.appointments.claim.alreadyApproved',
    defaultMessage: 'Vous avez déjà répondu à cette demande.',
    description: 'Erreur lorsque le claim a déjà été approuvé',
  },
  toastrAppointmentClaimAppointmentAlreadyExist: {
    id: 'toastr.appointments.claim.appointmentAlreadyExist',
    defaultMessage:
      'Félicitations, votre rendez-vous avec {firstname} a bien été enregistré.',
    description: 'Erreur lorsque le RDV existe déjà',
  },
  toastrAppointmentClaimNotValid: {
    id: 'toastr.appointments.claim.NotValid',
    defaultMessage:
      "Désolé, cette demande n'était pas valide. Nous vous invitons à fixer votre rendez-vous passé avec {firstname} depuis la page de votre conversation.",
    description: 'Erreur lorsque le claim n est pas valide',
  },
  toastrAppointmentClaimErrorUnknown: {
    id: 'toastr.appointments.claim.unknownError',
    defaultMessage: 'Désolé, une erreur est survenue.',
    description: 'Erreur lorsque le claim n est pas valide',
  },
  toastrAppointmentClaimErrorAppointmentAlreadyExist: {
    id: 'toastr.appointments.claim.errorAppointmentAlreadyExist',
    defaultMessage:
      'Désolé, vous ne pouvez pas demander un rendez-vous à ce professionnel car vous en avez déjà un',
    description:
      'Erreur lorsque l étudiant veut claim alors qu il y a deja un appointment',
  },
  conflictAppointment: {
    id: 'toastr.appointments.conflictAppointment',
    defaultMessage:
      'Vous avez déjà un rendez-vous sur ce créneau. Choisissez un autre créneau horaire.',
    description:
      'erreur lors de la creation de RDV au même moment qu un autre existant',
  },
  confirmCancelAppointmentModalMessage: {
    id: 'toastr.appointments.confirmCancelAppointmentModalMessage',
    defaultMessage: 'Souhaitez-vous vraiment supprimer ce rendez-vous ?',
    description: 'Page mes rencontres',
  },
  toastrDeletedAppointmentProfessionnal: {
    id: 'toastr.appointments.toastrAppointmentDeletedByProfessionnal',
    defaultMessage:
      'Votre RDV a bien été supprimé et votre Membre sera averti par email. N’oubliez pas de lui envoyer un message pour lui expliquer pourquoi vous avez annulé.',
    description: 'Message toastr à la suppression d un RDV',
  },
  toastrDeletedAppointmentStudent: {
    id: 'toastr.appointments.toastrAppointmentDeletedByStudent',
    defaultMessage:
      'Votre RDV a bien été annulé et votre pro sera averti par email. N’oubliez pas de lui envoyer un message pour lui dire pourquoi vous avez annulé, et proposer une autre date de rencontre.',
    description: 'Message toastr à la suppression d un RDV',
  },
});

export const CONFIRM_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT =
  'CONFIRM_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT';
export const CANCEL_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT =
  'CANCEL_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT';

export function* createOrUpdateAppointment() {
  while (true) {
    //eslint-disable-line no-constant-condition
    const { id, values, type, resolve, reject } = yield take([
      CREATE_APPOINTMENT,
      UPDATE_APPOINTMENT,
    ]);
    try {
      let position = {};
      if ((values?.type || 'physical') === 'physical') {
        position = yield call(getPosition, values['meeting_place']);
      }
      const currentPathname = yield select(currentRouterPathnameSelector);
      const match = matchPath(currentPathname, [
        Routes.downloadConversationAppointmentIcalendar,
        Routes.bookAppointment,
        Routes.claimAppointment,
        Routes.updateAppointment,
        Routes.deleteAppointment,
        Routes.conversationReportInterlocutor,
        Routes.conversation,
      ]);
      const conversationId = match?.params?.id;
      const day = moment(values['from-date']);
      const hour = moment(values['from-hour']);
      const computedFrom = day
        // Important to setOffset FIRST !
        // ... AND to get the offset from the local date already set in the day (to handle DST)
        // (using moment().local().utcOffset() would screw up DST 🤯)
        .utcOffset(day.local().utcOffset())
        .hour(hour.hour()) // Does return hours in UTC 🤯
        .minutes(hour.minutes());

      const isNewAppointment = type === CREATE_APPOINTMENT;
      const isPastAppointment = computedFrom.diff(moment()) < 0;

      // User choose to create an appointment in past
      if (isNewAppointment && isPastAppointment) {
        yield call(createAppointmentInThePast, {
          conversationId,
          from: computedFrom,
          type: values.type,
          meeting_place: values.meeting_place,
          position,
        });
      }
      // User choose to create an appointment (not in past)
      else if (isNewAppointment) {
        yield call(createAppointment, {
          conversationId,
          from: computedFrom,
          type: values.type,
          meeting_place: values.meeting_place,
          position,
        });
      }
      // User choose to update an appointment
      else {
        yield call(updateAppointment, {
          id,
          conversationId,
          from: computedFrom,
          type: values.type,
          meeting_place: values.meeting_place,
          position,
        });
      }
      resolve && resolve();
    } catch (error) {
      reject && reject();
      sendError('An error occured on appointment create/update', error);
    }
  }
}

/**
 * Watch claim appointment request
 */
export function* claimAppointmentWatcher() {
  const claimAppointmentTask = yield takeEvery(
    CLAIM_APPOINTMENT,
    function* ({ conversationId, values, resolve, reject }) {
      const preparedValues = yield call(prepareAppointmentValues, values);
      yield put(
        claimAppointmentResource({
          conversationId,
          ...preparedValues,
        }),
      );
      const { success, error } = yield race({
        success: take(
          createSuccessActionType(
            'CREATE',
            ApiResource.MESSAGING_APPOINTMENT_CLAIM,
          ),
        ),
        error: take(
          createErrorActionType(
            'CREATE',
            ApiResource.MESSAGING_APPOINTMENT_CLAIM,
          ),
        ),
      });
      if (success) {
        yield put(
          displayToastr(
            'success',
            t(appointmentSagaTranslations.successClaimToastr),
            undefined,
            { timeOut: 20000 },
          ),
        );
        yield put(
          push(generatePath(Routes.conversation, { id: conversationId })),
        );
        resolve && resolve();
        return;
      }
      const extractedErrors = extractErrorsFromPayload(error);
      if (
        extractedErrors?.[0]?.name === 'duplicate_claim_on_similar_appointment'
      ) {
        yield put(
          displayToastr(
            'error',
            t(appointmentSagaTranslations.successClaimToastr),
            undefined,
            { timeOut: 20000 },
          ),
        );
        yield put(
          push(generatePath(Routes.conversation, { id: conversationId })),
        );
        resolve && resolve();
        return;
      }
      if (extractedErrors?.[0]?.name === 'already_existing_appointment') {
        yield put(
          displayToastr(
            'error',
            t(
              appointmentSagaTranslations.toastrAppointmentClaimErrorAppointmentAlreadyExist,
            ),
            undefined,
            { timeOut: 20000 },
          ),
        );
        reject && reject();
        return;
      }
      yield put(
        displayToastr(
          'error',
          t(appointmentSagaTranslations.errorClaimToastr),
          undefined,
          { timeOut: 20000 },
        ),
      );
      reject && reject();
    },
  );
  // autokill saga when the route changes
  yield fork(function* () {
    yield take('@@router/LOCATION_CHANGE');
    yield cancel(claimAppointmentTask);
  });
}

/**
 * Prepare appointment value (position, from, to, type, meeting_place)
 * Before send it to action
 * @param {Object} values
 * @param {String} values.type - appointment type (physical, call, video)
 * @param {Object} [values.meeting_place]
 * @param {String} values.from-date
 * @param {String} values.from-hour
 */
export function* prepareAppointmentValues(values) {
  let position = {},
    meeting_place;
  const type = values?.type || 'physical';
  if (type === 'physical') {
    meeting_place = values?.meeting_place;
    position = yield call(getPosition, meeting_place);
  }
  const day = moment(values?.['from-date']);
  const hour = moment(values?.['from-hour']);
  const from = day
    // Important to setOffset FIRST !
    // ... AND to get the offset from the local date already set in the day (to handle DST)
    // (using moment().local().utcOffset() would screw up DST 🤯)
    .utcOffset(day.local().utcOffset())
    .hour(hour.hour()) // Does return hours in UTC 🤯
    .minutes(hour.minutes());
  return {
    position,
    type,
    meeting_place,
    from: from.format('YYYY-MM-DD HH:mm:00 ZZ'),
    to: from.add(1, 'h').format('YYYY-MM-DD HH:mm:00 ZZ'),
  };
}

/**
 * Create a classical appointment
 * @param {Object} params
 */
export function* createAppointment({
  conversationId,
  type,
  from,
  meeting_place,
  position,
}) {
  try {
    yield put(
      createAppointmentResource({
        conversationId,
        type,
        from,
        meeting_place,
        position,
      }),
    );
    const { success, error } = yield race({
      success: take(
        getCreateSuccessActionType(ApiResource.MESSAGING_APPOINTMENT),
      ),
      error: take(getCreateErrorActionType(ApiResource.MESSAGING_APPOINTMENT)),
    });
    if (success) {
      toastr.success(t(appointmentSagaTranslations.successAppointmentCreated));
      yield put(
        push(generatePath(Routes.conversation, { id: conversationId })),
      );
      yield put(getAppointmentsList());
    } else {
      yield call(appointmentErrorsHandler, error);
    }
  } catch (error) {
    toastr.error(t(appointmentSagaTranslations.errorAppointmentCreate));
    sendError('An error occured during update an appointment', error);
  }
}

/**
 * Create an appointment in past
 * @param {Object} params
 */
export function* createAppointmentInThePast({
  conversationId,
  type,
  from,
  meeting_place,
  position,
}) {
  try {
    const confirmationToastr = yield call(
      askUserToConfirmcreateAppointmentInThePast,
    );
    const confirmation = yield take(confirmationToastr);
    if (confirmation === CANCEL_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT) {
      return;
    }
    yield put(
      createAppointmentResource({
        conversationId,
        type,
        from,
        meeting_place,
        position,
      }),
    );
    const { success, error } = yield race({
      success: take(
        getCreateSuccessActionType(ApiResource.MESSAGING_APPOINTMENT),
      ),
      error: take(getCreateErrorActionType(ApiResource.MESSAGING_APPOINTMENT)),
    });
    if (success) {
      toastr.success(t(appointmentSagaTranslations.successAppointmentCreated));
      yield put(
        push(generatePath(Routes.conversation, { id: conversationId })),
      );
      yield put(
        push({
          pathname: Routes.appointments,
          hash: 'past',
        }),
      );
      yield put(getAppointmentsList());
      yield put(getProfile());
    } else {
      yield call(appointmentErrorsHandler, error);
    }
  } catch (error) {
    toastr.error(t(appointmentSagaTranslations.errorAppointmentCreate));
    sendError('An error occured during create an appointment in past', error);
  }
}

/**
 * Handle common appointment errors
 * @param {Object} errorPayload
 */
export function* appointmentErrorsHandler(errorPayload) {
  const message = extractReadableErrorMessageFromErrorPayload(errorPayload, {
    initiator_is_soft_deleted: t(
      appointmentSagaTranslations.studentSoftDeleted,
    ),
    cannot_create_overlapping_appointment: t(
      appointmentSagaTranslations.conflictAppointment,
    ),
    default: t(appointmentSagaTranslations.errorAppointmentCreate),
  });
  if (message) {
    yield put(displayToastr('error', message));
  }
}

/**
 * Create a channel with a confirm toastr
 */
export function askUserToConfirmcreateAppointmentInThePast() {
  return eventChannel((emit) => {
    toastr.confirm(
      t(appointmentSagaTranslations.confirmCreateAppointmentInThePast),
      {
        onOk: () => emit(CONFIRM_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT),
        onCancel: () => emit(CANCEL_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT),
      },
    );

    // return an unsubscription function
    return () => emit(CANCEL_CREATE_PAST_APPOINTMENT_CHANNEL_EVENT);
  });
}

/**
 * Update an appointment
 * @param {Object} params
 */
export function* updateAppointment({
  id,
  conversationId,
  type,
  from,
  meeting_place,
  position,
}) {
  try {
    yield put(
      updateAppointmentResource({ id, type, from, meeting_place, position }),
    );
    const { success } = yield race({
      success: take(
        getUpdateSuccessActionType(ApiResource.MESSAGING_APPOINTMENT),
      ),
      error: take(getUpdateErrorActionType(ApiResource.MESSAGING_APPOINTMENT)),
    });
    if (success) {
      toastr.success(t(appointmentSagaTranslations.successUpdateAppointment));
      yield put(
        push(generatePath(Routes.conversation, { id: conversationId })),
      );
      yield put(getConversation({ id: conversationId }));
    } else {
      toastr.error(t(appointmentSagaTranslations.errorAppointmentCreate));
    }
  } catch (error) {
    toastr.error(t(appointmentSagaTranslations.errorAppointmentCreate));
    sendError('An error occured during create an appointment', error);
  }
}

export function* hydrateAppointmentsView() {
  yield put(setContainerLoadState('appointment-view', { loading: true }));
  yield put(getAppointmentsList());
  const { success, error } = yield race({
    success: take(
      createSuccessActionType('READ_LIST', ApiResource.MESSAGING_APPOINTMENT),
    ),
    error: take(
      createErrorActionType('READ_LIST', ApiResource.MESSAGING_APPOINTMENT),
    ),
  });
  if (success) {
    yield put(
      setContainerLoadState('appointment-view', {
        loaded: true,
        success: true,
      }),
    );
  } else {
    yield put(setContainerLoadState('appointment-view', { error }));
  }
}

/**
 * Display reviews based on review appointment tasks
 */
export function* displayPendingReviews() {
  const mustBeReviewedAppointments = yield select(
    ownPendingAppointmentReviewTasksModelSelector,
  );
  // use appointmentID to display modal (instead of review id)
  const mustBeReviewedAppointmentIds = mustBeReviewedAppointments.map(
    ({ appointmentId }) => appointmentId,
  );

  for (let appointmentId of mustBeReviewedAppointmentIds) {
    yield put(getAppointment({ id: appointmentId }));
    const { error } = yield race({
      success: take(
        createSuccessActionType('READ', ApiResource.MESSAGING_APPOINTMENT),
      ),
      error: take(
        createErrorActionType('READ', ApiResource.MESSAGING_APPOINTMENT),
      ),
    });
    if (error) {
      sendError('A user failed to load his appointment review modal', error, {
        appointmentId,
      });
      return;
    }
  }
}
