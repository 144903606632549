import { call, takeEvery } from 'redux-saga/effects';
import { RUN_DOWNLOAD } from '../actions/download';
import { getHeaders } from './api';
import { sendError } from 'appsignal/appsignal';

/**
 * function to call to run download
 * @param {Blob} blob - blob to download
 * @param {String} fileName - fileName of download file
 * @param {String} fileType
 */
const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  return function (blob, fileName, fileType = 'text/calendar') {
    blob = new Blob([blob], { type: fileType });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

/**
 * Run download / save of distant file (through authenticated fetch request)
 * @param {String} route - route on which retrieve file
 * @param {String} filename - filename of file to save
 * @param {String} fileType
 */
// eslint-disable-next-line require-yield
export function* runDownload(route, filename, fileType) {
  const headers = getHeaders();
  // eslint-disable-next-line
  fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_DOWNLOAD_HOST}/${route}`, {
    headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error('Network response was not ok.');
    })
    .then((blob) => saveData(blob, filename, fileType))
    .catch((e) => sendError('Error during perform download file request', e));
}

export function* watchDownload() {
  yield takeEvery(RUN_DOWNLOAD, function* ({ url, fileName, fileType }) {
    yield call(runDownload, url, fileName, fileType);
  });
}
