import { JsonApiMessageTemplateMapped } from '../../../../@types/jsonapi';
import {
  createResource,
  deleteResource,
  readListResource,
  updateResource,
} from '../../../../actions/api';
import ApiResource from '../../../../api-resources';

export const getMessageTemplateList = () =>
  readListResource({
    resourceUri: '/professional/message_templates',
    data: {
      type: ApiResource.MESSAGE_TEMPLATE,
    },
  });

export const createMessageTemplate = (
  { title, body }: Pick<JsonApiMessageTemplateMapped, 'title' | 'body'>,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) =>
  createResource(
    {
      resourceUri: '/professional/message_templates',
      data: {
        type: ApiResource.MESSAGE_TEMPLATE,
        attributes: {
          title,
          body,
        },
      },
    },
    resolve,
    reject,
  );

export const editMessageTemplate = (
  { id, title, body }: JsonApiMessageTemplateMapped,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) =>
  updateResource(
    {
      resourceUri: `/professional/message_templates/${id}`,
      data: {
        id,
        type: ApiResource.MESSAGE_TEMPLATE,
        attributes: {
          title,
          body,
        },
      },
    },
    resolve,
    reject,
  );

export const deleteMessageTemplate = ({
  id,
}: Pick<JsonApiMessageTemplateMapped, 'id'>) =>
  deleteResource({
    resourceUri: `/professional/message_templates/${id}`,
    data: {
      id,
      type: ApiResource.MESSAGE_TEMPLATE,
    },
  });
