import moment from 'moment';

/* Store Smart URL - automatically adds a date information
 * @param { Object } params
 * @param { String } params.type - Identity the type of SmartUrl (student, pro, etc.)
 * @param { String } params.path
 * @param { Context } params.context
 */
export const storeSmartURL = ({ type, pathname, context }) => {
  const serializedInformation = JSON.stringify({
    storeDate: moment(),
    pathname,
    context,
  });
  window.localStorage?.setItem?.(smartUrlId(type), serializedInformation);
};

/**
 * @deprecated
 * @param {*} type
 * @return { { path, context, storeDate } or null }
 */
export const readSmartURL = (type) => {
  const deserialized = JSON.parse(
    window.localStorage.getItem(smartUrlId(type)),
  );
  if (!deserialized) {
    return null;
  }
  deserialized.storeDate = moment(deserialized.storeDate);
  return deserialized;
};

const smartUrlId = (type) => {
  return `${type}SmartUrl`;
};
