import React from 'react';
import { useSelector } from 'react-redux';
import { ProfileType } from '../../@types/jsonapi.d';
import NotFound from '../../containers/not-found/NotFound';
import PageLoader from '../../scenes/common/page-loader/PageLoader';
import { userSelector } from '../../selectors';
import {
  isCompanyAdmin,
  isMJGAdmin,
  isSchoolCoach,
} from '../../utils/typed/user';
import { containerLoadStateSelector } from '../../selectors/container-load-state';
import { isAmbassador, isMentor, isStudent } from '../../utils/user';

interface ProtectRouteOptions {
  allowedProfiles: ProfileType[];
}

export function redirectToAccount() {
  // eslint-disable-next-line
  window.location.href = `${window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL}?redirect=${encodeURIComponent(window.location.href)}`;
}

export default function protectRoute<T extends object>(
  Component: React.ComponentType<T>,
  { allowedProfiles }: ProtectRouteOptions,
): React.ComponentType<any> {
  return function WithRowRendererWrapper(props: T) {
    const currentUser = useSelector(userSelector);
    const { loaded, error } = useSelector(
      containerLoadStateSelector('bootstrap-current-user'),
    );

    // eslint-disable-next-line
    if (error && window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL) {
      redirectToAccount();
    }

    if (
      (allowedProfiles.indexOf(ProfileType.companyAdmin) > -1 &&
        isCompanyAdmin(currentUser)) ||
      (allowedProfiles.indexOf(ProfileType.MJGAdmin) > -1 &&
        isMJGAdmin(currentUser)) ||
      (allowedProfiles.indexOf(ProfileType.student) > -1 &&
        isStudent(currentUser)) ||
      (allowedProfiles.indexOf(ProfileType.mentor) > -1 &&
        isMentor(currentUser)) ||
      (allowedProfiles.indexOf(ProfileType.employee) > -1 &&
        isAmbassador(currentUser)) ||
      (allowedProfiles.indexOf(ProfileType.schoolCoach) > -1 &&
        isSchoolCoach(currentUser))
    ) {
      return (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }

    if (!loaded) {
      return <PageLoader />;
    }

    return <NotFound />;
  };
}
