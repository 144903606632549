import React, {
  ComponentType,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react';
import { IconProps } from 'icons/component-icons/Icon';
import classNames from 'classnames';

import './Modal.scss';
import XCircleIcon from 'icons/component-icons/XCircleIcon';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  icon?: ComponentType<IconProps>;
  closeButton?: boolean;
  title?: string;
  subtitle?: string | ReactNode;
  primaryButton?: {
    label: string;
    onClick: () => void;
    icon?: ComponentType<IconProps>;
    disabled?: boolean;
  };
  secondaryButton?: {
    label: string;
    onClick: () => void;
  };
  className?: string;
};

export default function Modal({
  isOpen,
  onClose,
  closeButton,
  icon: Icon,
  title,
  subtitle,
  children,
  primaryButton,
  secondaryButton,
  className,
}: PropsWithChildren<ModalProps>) {
  if (!isOpen) return null;

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="mjg-modal">
      <button
        type="button"
        className="mjg-modal__overlay"
        onClick={onClose}
      />

      <div className="mjg-modal__container">
        {title && (
          <div className="mjg-modal__container__header">
            <div>
              {Icon && <Icon />}
              {title}
              {closeButton && (
                <button
                  type="button"
                  onClick={onClose}
                  className="mjg-modal__container__header__close"
                >
                  <XCircleIcon />
                </button>
              )}
            </div>
            {subtitle && <p>{subtitle}</p>}
          </div>
        )}

        {children && (
          <div
            className={classNames('mjg-modal__container__content', className)}
          >
            {children}
          </div>
        )}

        <div className="mjg-modal__container__footer">
          {secondaryButton && (
            <button
              type="button"
              onClick={secondaryButton.onClick}
              className="mjg-modal__container__footer__button"
            >
              {secondaryButton.label}
            </button>
          )}

          {primaryButton && (
            <button
              type="button"
              onClick={
                !primaryButton.disabled ? primaryButton.onClick : () => {}
              }
              className={classNames(
                'mjg-modal__container__footer__button',
                'mjg-modal__container__footer__button--primary',
                {
                  'mjg-modal__container__footer__button--primary--disabled':
                    primaryButton.disabled,
                }
              )}
            >
              {primaryButton.label}
              {primaryButton.icon && <primaryButton.icon />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
