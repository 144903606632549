import React, { useContext } from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { CompanyAdminRoutes, MJGAdminRoutes } from 'routes';
import { CompanyViewParams } from '../../../../../../../@types/routes-params.d';
import UsernavContext from '../../../../context/UsernavContext';
import { WaterfallProps } from '../../../../@types/HeaderConfig.d';

function UsernavItemCompanyLink({
  to,
  companyId,
  label,
  className,
}: WaterfallProps) {
  const match = useRouteMatch<CompanyViewParams>({
    path: [
      ...Object.values(CompanyAdminRoutes),
      ...Object.values(MJGAdminRoutes),
    ],
    exact: true,
  });
  const { closeUsernavMenu } = useContext(UsernavContext);
  function onClick() {
    closeUsernavMenu();
  }
  if (!to || (!match?.params.companyid && !companyId)) {
    return null;
  }
  return (
    <Link
      to={generatePath(to, { companyid: match?.params.companyid })}
      className={className}
      onClick={onClick}
    >
      {label}
    </Link>
  );
}

export default UsernavItemCompanyLink;
