import { push, replace } from 'connected-react-router';
import camelCase from 'lodash.camelcase';
import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';
import { generatePath } from 'react-router';
import { actionTypes, getFormSyncErrors } from 'redux-form';
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { setContainerLoadState } from '../actions/container-load-state';
import {
  SUBMIT_CONVERSATION_CREATION,
  createConversation,
  requestProfessionalAvailabilities,
} from '../actions/conversations';
import { AUTO_SIGNIN_SUCCESS } from '../actions/signin';
import { BOOTSTRAPPING_SUCCESS } from '../actions/user';
import { updateProfile } from '../actions/profile';
import { displayToastr } from '../actions/toastr';
import ApiResource from '../api-resources';
import Routes from '../routes';
import { userSelector } from '../selectors';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import { extractErrorsFromPayload } from '../utils/json-api';
import { t } from '../utils/translate';
import { retrieveProfessional } from '../scenes/public/profile/sagas/hydrateProfessionalView';
import { sendError } from 'appsignal/appsignal';

export const conversationCreationTranslations = defineMessages({
  mustBeAStudentToContactPros: {
    id: 'toastr.conversationInitiate.mustBeAStudentToContactPros',
    defaultMessage:
      'Seuls les Membres peuvent contacter des professionnels. Vous êtes connecté avec un compte professionnel.',
    description: 'Tentative de contact d un pro via un compte pro',
  },
  cannotContactWithSoftDeletedAccount: {
    id: 'toastr.conversationInitiate.cannotContactWithSoftDeletedAccount',
    defaultMessage:
      'Vous avez supprimé votre compte, vous ne pouvez pas contacter ce professionnel.',
    description: 'Tentative de contact d un pro avec un compte soft deleted',
  },
  reachedWeeklyStudentContactQuota: {
    id: 'toastr.conversationInitiate.reachedWeeklyStudentContactQuota',
    defaultMessage:
      'Bravo ! Vous avez atteint votre quota de 3 prises de contact par semaine, soyez bien attentif aux retours des Ambassadeurs. RDV lundi pour 3 nouvelles prises de contact.',
    description:
      'Tentative de contact d un étudiant qui a fait son nb de contact hebdomadaire',
  },
  proUnavailableUntil: {
    id: 'toastr.conversationInitiate.proUnavailableUntil',
    defaultMessage:
      "Désolé, {firstName} est indisponible jusqu'au {unavailability}",
    description: 'Tentative de contact d un pro indisponible',
  },
  cannotContactOneself: {
    id: 'toastr.conversationInitiate.cannotContactOneself',
    defaultMessage: 'Vous êtes disponible au {phone} ;-)',
    description: 'Tentative de contact de soi-même',
  },
  cannotContactUnpublishedOrIcedUpOrSoftDeletedProfessional: {
    id: 'toastr.conversationInitiate.cannotContactUnpublishedOrIcedUpOrSoftDeletedProfessional',
    defaultMessage:
      "Désolé, {firstName} a supprimé son compte et n'est plus contactable",
    description: 'Tentative de contact d un pro indisponible',
  },
  sendMessageSuccess: {
    id: 'toastr.conversationInitiate.sendMessageSuccess',
    defaultMessage: 'Votre message a bien été envoyé, continuez !',
    description: 'Envoi de message',
  },
  sendMessageError: {
    id: 'toastr.conversationInitiate.sendMessageError',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
    description: 'Envoi de message',
  },
});

const conversationInitiationTasks = [];

/**
 * Hydrate conversation initiation view
 * @param {String} professionalId
 */
export function* hydrateConversationCreationView(professionalId) {
  yield put(setContainerLoadState('conversationInitiation', { loading: true }));
  const professional = yield call(retrieveProfessional, professionalId);
  if (professional) {
    const professionalAvailable = yield call(
      checkAvailabilityForNewConversation,
      professional,
    );
    if (professionalAvailable !== true) {
      if (professionalAvailable === 'redirect') {
        yield put(
          push(`${generatePath(Routes.professional, { id: professionalId })}`),
        );
      }
      return;
    }
    // Handle message submit event
    const conversationCreationSubmitHandler = yield fork(
      handleConversationCreationSubmission,
      professional,
    );
    conversationInitiationTasks.push(conversationCreationSubmitHandler);
    // Watch for field change
    const conversationCreationDraftWatcher = yield fork(
      watchDraftableFieldEdition,
    );
    conversationInitiationTasks.push(conversationCreationDraftWatcher);
  }
  yield put(
    setContainerLoadState('conversationInitiation', {
      loading: false,
      loaded: true,
    }),
  );
  yield call(requireUserBootstrappingToContinue);
  yield takeEvery('@@router/LOCATION_CHANGE', function* () {
    yield all(conversationInitiationTasks.map((task) => cancel(task)));
  });
}

/**
 * Ensure current user is correctly loaded
 */
export function* requireUserBootstrappingToContinue() {
  let currentUser = yield select(userSelector);
  if (!currentUser) {
    yield take([AUTO_SIGNIN_SUCCESS, BOOTSTRAPPING_SUCCESS]);
  }
  currentUser = yield select(userSelector);
}

/**
 * Check if professional is avialable for conversation initiation
 * @param {Object} professional
 */
export function* checkAvailabilityForNewConversation(professional) {
  yield put(
    requestProfessionalAvailabilities({
      profileId: professional.id,
      profileType: 'Mentor',
    }),
  );
  const { error } = yield race({
    success: take(
      createSuccessActionType('READ', 'messaging/new_conversation'),
    ),
    error: take(createErrorActionType('READ', 'messaging/new_conversation')),
  });
  if (error) {
    return yield call(handleConversationCreationErrors, error, professional);
  }
  return true;
}

/**
 * Watch all page events to launch respective saga
 * @param {Object} professional
 */
export function* handleConversationCreationSubmission(professional) {
  yield takeEvery(
    SUBMIT_CONVERSATION_CREATION,
    submitConversationCreation,
    professional,
  );
}

/**
 * Request server to submit conversation initiation
 * @param {Object} professional
 * @param {Object} params
 * @param {String} params.message
 */
export function* submitConversationCreation(professional, { message }) {
  yield put(
    createConversation({
      profileId: professional.id,
      profileType: professional.isMentor ? 'mentor' : 'company/employee',
      text: message,
    }),
  );
  yield put(setContainerLoadState('conversationInitiation', { sending: true }));
  window.scrollTo && window.scrollTo(0, 0);
  const { success, error } = yield race({
    success: take(
      createSuccessActionType('CREATE', ApiResource.MESSAGING_CONVERSATION),
    ),
    error: take(
      createErrorActionType('CREATE', ApiResource.MESSAGING_CONVERSATION),
    ),
  });
  if (success) {
    yield put(
      setContainerLoadState('conversationInitiation', { success: true }),
    );
    yield delay(3000);
    yield put(push(Routes.search, { isRecommendations: true }));
  } else if (error) {
    const errors = extractErrorsFromPayload(error);
    const errorCode = errors?.[0]?.name;
    yield put(
      setContainerLoadState('conversationInitiation', {
        error: getErrorTranslation(errorCode, professional) || true,
      }),
    );
    yield delay(3000);
    yield put(
      setContainerLoadState('conversationInitiation', { loaded: true }),
    );
  }
}

/**
 * Get matching translation from error code
 * @param {String} errorCode
 * @param {Object} professional
 */
export function getErrorTranslation(errorCode, professional) {
  switch (errorCode) {
    case 'conversation_already_started':
      return;
    case 'conversation_quota_exceeded':
    case 'appointment_quota_exceeded':
    case 'hibernated':
    case 'in_warmup':
    case 'in_red_crisis':
    case 'in_crisis':
      return t(conversationCreationTranslations.proUnavailableUntil, {
        firstName: professional?.user?.firstName,
        unavailability: moment(professional?.unavailableUntil).format(
          'DD/MM/YYYY',
        ),
      });
    case 'must_be_a_student':
      return t(conversationCreationTranslations.mustBeAStudentToContactPros);
    case 'contact_quota_exceeded':
      return t(
        conversationCreationTranslations.reachedWeeklyStudentContactQuota,
      );
    case 'user_is_soft_deleted_initiator':
      return t(
        conversationCreationTranslations.cannotContactWithSoftDeletedAccount,
      );
    case 'user_is_soft_deleted_recipient':
      return t(
        conversationCreationTranslations.cannotContactUnpublishedOrIcedUpOrSoftDeletedProfessional,
        {
          firstName: professional?.user?.firstName,
        },
      );
    case 'iced_up':
    case 'not_published':
      return t(
        conversationCreationTranslations.cannotContactUnpublishedOrIcedUpOrSoftDeletedProfessional,
        {
          firstName: professional?.user?.firstName,
        },
      );
    case 'contact_different_user':
      return t(conversationCreationTranslations.cannotContactOneself, {
        phone: professional?.user?.phone,
      });
  }
}

/**
 * Handle errors payload
 * @param {Object} professional
 * @param {Object} errorPayload
 */
export function* handleConversationCreationErrors(errorPayload, professional) {
  const errors = extractErrorsFromPayload(errorPayload);
  const errorCode = errors?.[0]?.name;
  switch (errorCode) {
    // Message when conversation already started
    case 'conversation_already_started': {
      const conversationId = errorPayload?.error?.[0]?.meta?.conversation_id;
      yield put(
        replace(generatePath(Routes.conversation, { id: conversationId })),
      );
      return 'no_redirect';
    }
    // Message when the professional is not available for some reason
    case 'conversation_quota_exceeded':
    case 'appointment_quota_exceeded':
    case 'hibernated':
    case 'in_warmup':
    case 'in_red_crisis':
    case 'in_crisis': {
      yield put(
        displayToastr('warning', getErrorTranslation(errorCode, professional), {
          timeOut: 30000,
        }),
      );
      if (!professional?.unavailableUntil) {
        sendError(
          `An error occured >>>${errors?.[0]?.name}<<< without unavaible until date`,
          undefined,
          errorPayload,
        );
      }
      break;
    }
    // Only students can contact Ambassadeurs
    case 'must_be_a_student': {
      yield put(
        displayToastr('error', getErrorTranslation(errorCode, professional), {
          timeOut: 30000,
        }),
      );
      break;
    }
    // message when student (connected) has exceed max contact for this week
    // Showing as green to indicate it's a good thing
    case 'contact_quota_exceeded': {
      yield put(
        displayToastr('success', getErrorTranslation(errorCode, professional), {
          timeOut: 30000,
        }),
      );
      break;
    }
    // Unpublishes ~= Soft deleted ~= Iced up
    case 'user_is_soft_deleted': {
      // But when the current_user is soft deleted, we need a custom message
      const pointer = errors?.[0]?.source?.pointer;
      if (pointer.match(/initiator/)) {
        yield put(
          displayToastr(
            'error',
            getErrorTranslation(errorCode + '_initiator', professional),
            { timeOut: 30000 },
          ),
        );
      } else if (pointer.match(/recipient/)) {
        yield put(
          displayToastr(
            'error',
            getErrorTranslation(errorCode + '_recipient', professional),
            { timeOut: 30000 },
          ),
        );
      }
      break;
    }
    case 'iced_up':
    case 'not_published': {
      yield put(
        displayToastr('error', getErrorTranslation(errorCode, professional), {
          timeOut: 30000,
        }),
      );
      break;
    }
    case 'contact_different_user': {
      yield put(
        displayToastr('info', getErrorTranslation(errorCode, professional), {
          timeOut: 30000,
        }),
      );
      break;
    }
    default: {
      sendError(
        'Unknown error when trying to load a #new conversation',
        undefined,
        errors,
      );
      break;
    }
  }
  return 'redirect';
}

/**
 * Autosave fields on blur event
 * Update profile draft fields
 */
export function* watchDraftableFieldEdition() {
  yield takeEvery(actionTypes.BLUR, function* (data) {
    let success, error;
    const fieldName = data?.meta?.field;
    const formErrors = yield select(getFormSyncErrors('conversation-initiate'));
    if (formErrors?.[fieldName] || (data?.payload || '').trim().length < 1) {
      yield put(setContainerLoadState(`message_${fieldName}_draft`, {}));
      return;
    }
    if (['presentation', 'explanation'].indexOf(fieldName) > -1) {
      // Check if pristine
      const user = yield select(userSelector);
      const profile = user?.profiles?.find((e) => e.type === 'student');
      const currentValue = profile?.[camelCase(`message_${fieldName}_draft`)];
      if (currentValue === data?.payload) {
        return;
      }
      // Save current value as draft
      yield put(
        setContainerLoadState(`message_${fieldName}_draft`, { loading: true }),
      );
      yield put(
        updateProfile({
          profileId: profile?.id,
          userType: 'student',
          attributes: {
            [`message_${fieldName}_draft`]: data?.payload,
          },
        }),
      );
      ({ success, error } = yield race({
        success: take(createSuccessActionType('UPDATE', 'profile/student')),
        error: take(createErrorActionType('UPDATE', 'profile/student')),
      }));
    } else {
      success = true;
    }
    if (success) {
      yield put(
        setContainerLoadState(`message_${fieldName}_draft`, { loaded: true }),
      );
    } else if (error) {
      yield put(
        setContainerLoadState(`message_${fieldName}_draft`, { error: true }),
      );
    }
  });
  // reset all fields status
  yield takeEvery(
    createSuccessActionType('CREATE', ApiResource.MESSAGING_CONVERSATION),
    function* () {
      yield all(
        ['pitch', 'valediction'].map((fieldName) =>
          put(setContainerLoadState(`message_${fieldName}_draft`, {})),
        ),
      );
    },
  );
}
