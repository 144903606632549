import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ProfileType } from '../../../../../@types/jsonapi.d';
import Avatar from '../../../../../components/avatar/Avatar';
import SchoolCoachAvatar from '../../../../../icons/SchoolCoachAvatar';
import { numberOfMissingInformationInProfileSelector } from '../../../../../selectors/profile';
import { useDropdownFeature } from '../../../../../utils/hooks/useOutsideClickEffect';
import { useSelector } from '../../../../../utils/hooks/useRedux';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import UsernavContext from '../../context/UsernavContext';

import './UsernavConnected.scss';

const UsernavConnected = ({ currentUser: currentUserJSONAPI, config }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [ref, toggle, close, { isActive }] = useDropdownFeature();
  const totalNotifications = useSelector(
    numberOfMissingInformationInProfileSelector,
  );
  return (
    <UsernavContext.Provider value={{ closeUsernavMenu: close }}>
      <div
        className="usernav-connected"
        ref={ref}
      >
        <button
          type="button"
          className={classnames('usernav-connected-avatar', {
            active: isActive,
          })}
          onClick={toggle}
        >
          {currentUserJSONAPI?.type === ProfileType.schoolCoach ? (
            <SchoolCoachAvatar className="avatar" />
          ) : (
            <Avatar
              avatar={currentUser.avatars}
              className="avatar"
            />
          )}
          {!!totalNotifications && (
            <span className="global-notification-count" />
          )}
        </button>
        {Array.isArray(config) && isActive && (
          <ul className="usernav-connected-dropdown">
            {config.map(
              ({
                component: WrappedComponent,
                condition,
                type,
                className,
                label,
                to,
                key,
                onClick,
                isNotificationSelector,
              }) =>
                !condition || condition({ currentUser }) ? (
                  <li
                    className="usernav-connected-dropdown-item"
                    key={key}
                  >
                    <WrappedComponent
                      type={type}
                      data-testid={key}
                      className={className}
                      label={label}
                      to={to}
                      onClick={onClick}
                      isNotificationSelector={isNotificationSelector}
                    />
                  </li>
                ) : null,
            )}
          </ul>
        )}
      </div>
    </UsernavContext.Provider>
  );
};

UsernavConnected.propTypes = {
  currentUser: PropTypes.shape({
    type: PropTypes.string,
    avatars: PropTypes.shape({}),
  }).isRequired,
  config: PropTypes.arrayOf(PropTypes.shape({})),
};
UsernavConnected.defaultProps = {
  config: undefined,
};

export default UsernavConnected;
