import Appsignal from '@appsignal/javascript';
import { plugin as pluginpathdecorator } from '@appsignal/plugin-path-decorator';

export const appsignal = new Appsignal({
  // prettier-ignore
  key: window.__RUNTIME_CONFIG__.REACT_APP_APPSIGNAL_APP_FRONTEND_MONITORING_KEY, // eslint-disable-line
  revision: window.__RUNTIME_CONFIG__.REACT_APP_CURRENT_APHRODITE_VERSION || '', // eslint-disable-line
});

appsignal.use(pluginpathdecorator());

export const sendError = (
  message: string,
  error?: any,
  options?: { [key: string]: any },
) => {
  appsignal.sendError(error || new Error(message), (span: any) => {
    span.setAction(message);
    if (options) {
      span.setTags(options);
    }
  });
};
