import { HIDE_HOW_IT_WORK_BANNER } from '../actions/current-user-data';
import { BOOTSTRAPPING_SUCCESS } from '../actions/user';
import { objectValueFromPosition } from '../utils/dev-tools';

const initialState = {
  showHowItWorkBanner: true,
  email: undefined,
  userId: undefined,
};

/**
 * A collections to handle data about current user information
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case BOOTSTRAPPING_SUCCESS: {
      const user = objectValueFromPosition(0, action.data?.user);

      return {
        ...state,
        userId: user.id,
        email: user.attributes.email,
      };
    }

    case HIDE_HOW_IT_WORK_BANNER:
      return {
        ...state,
        showHowItWorkBanner: false,
      };

    default:
      return state;
  }
};
