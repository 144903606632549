import React, { Suspense } from 'react';
import { sendError } from 'appsignal/appsignal';
import { ApolloProvider } from '@apollo/client';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Route, Switch } from 'react-router';
import { SettingsContextProvider } from 'contexts/settings-context/SettingsContext';
import { CurrentUserContextProvider } from 'contexts/current-user-context/CurrentUserContext';
import OptionalIntercomContainer from './containers/intercom/IntercomContainer';
import Layout from './containers/layout/Layout';
import Modals from './containers/modals/Modals';
import client from './graphql';
import { languageWithoutRegionCode, messages } from './locales/configure';
import Routes from './routes';
import NoNetwork from './scenes/common/no-network/NoNetwork';
import PageLoader from './scenes/common/page-loader/PageLoader';
import RouterHive from './scenes/common/router-hiver/RouterHive';
import store, { history } from './store';

import styles from './Router.module.scss';
import './Router.scss';

const MainApp = React.lazy(() => import('./App'));
const MJGAdminRouter = React.lazy(
  () => import('./scenes/mjg-admin/MJGAdminRouter'),
);
const CompanyAdminRouter = React.lazy(
  () => import('./scenes/company-admin/CompanyAdminRouter'),
);
const SchoolAssignmentsRouter = React.lazy(
  () => import('./scenes/school-assignments/SchoolAssignmentsRouter'),
);

class Router extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e: Error) {
    if (e?.name === 'ChunkLoadError') {
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(e: Error) {
    sendError(e.message, e);
    throw e;
  }

  render() {
    const { hasError } = this.state;
    return (
      <ApolloProvider client={client}>
        <IntlProvider
          locale={languageWithoutRegionCode}
          messages={messages}
        >
          <Suspense fallback={<PageLoader />}>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <OptionalIntercomContainer>
                  <SettingsContextProvider>
                    <CurrentUserContextProvider>
                      <Layout>
                        <ReduxToastr
                          timeOut={4000}
                          newestOnTop={false}
                          preventDuplicates
                          position="top-left"
                          transitionIn="fadeIn"
                          transitionOut="fadeOut"
                          progressBar
                        />
                        <Modals />
                        <SnackbarProvider
                          maxSnack={5}
                          classes={{
                            variantSuccess: styles.successToastr,
                            variantError: styles.errorToastr,
                            variantWarning: styles.warningToastr,
                            variantInfo: styles.infoToastr,
                          }}
                          hideIconVariant
                        >
                          <RouterHive />

                          {hasError ? (
                            <NoNetwork
                              refetch={() => window.location.reload()}
                            />
                          ) : (
                            <Switch>
                              <Route
                                path={Routes.companyAdminBase}
                                component={CompanyAdminRouter}
                              />
                              <Route
                                path={Routes.mjgAdminBase}
                                component={MJGAdminRouter}
                              />
                              <Route
                                path={Routes.schoolAssignmentsBase}
                                component={SchoolAssignmentsRouter}
                              />
                              <Route component={MainApp} />
                            </Switch>
                          )}
                        </SnackbarProvider>
                      </Layout>
                    </CurrentUserContextProvider>
                  </SettingsContextProvider>
                </OptionalIntercomContainer>
              </ConnectedRouter>
            </Provider>
          </Suspense>
        </IntlProvider>
      </ApolloProvider>
    );
  }
}

export default Router;
