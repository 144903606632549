import { createSelector } from 'reselect';
import camelCase from 'lodash.camelcase';
import moment from 'moment-timezone';
import { notCancelledAppointmentsSelector } from '../../../../../selectors/appointments';
import { userSelector } from '../../../../../selectors';

/**
 * Get upcoming appointment
 * used in dashboard
 * @return {Object}
 */
export const upcomingAppointmentSelector = createSelector(
  notCancelledAppointmentsSelector,
  (appointments) => {
    if (appointments.length === 0) return {};
    const appointmentsFiltered = appointments.filter((appointment) =>
      moment(appointment.from).isAfter(moment()),
    );
    if (appointmentsFiltered.length === 0) return {};
    const upcomingAppointment = appointmentsFiltered.reduce((a, b) =>
      new Date(a.from) - new Date(b.from) > 1 ? b : a,
    );
    return upcomingAppointment;
  },
);

/**
 * Get gamification scores details
 * @return {Array}
 */
export const gamificationScoreDetailsSelector = createSelector(
  userSelector,
  (user) => user?.profile?.gamificationScoreDetails || [],
);

/**
 * Get gamification scores total
 * @return {Number|null}
 */
export const gamificationScoreTotalSelector = createSelector(
  userSelector,
  (user) => user?.profile?.gamificationScore || null,
);

/**
 * @return {Number|null}
 */
export const gamificationRankSelector = createSelector(userSelector, (user) => {
  const ladderRank = user?.profile?.gamificationRank || null;
  return ladderRank ? Math.round(ladderRank * 100) : null;
});

/**
 * @return {String|null}
 */
export const gamificationLeaderboardSelector = createSelector(
  userSelector,
  (user) => user?.profile?.gamificationLeaderboard || null,
);

/**
 * @return {Object}
 */
export const missionsSelector = createSelector(userSelector, (user) => ({
  previousMissionCompleted:
    camelCase(user?.profile?.previousMissionCompleted || null) || null,
  currentMissionCompleted:
    camelCase(user?.profile?.currentMissionCompleted || null) || null,
  nextMissionToComplete:
    camelCase(user?.profile?.nextMissionToComplete || null) || null,
}));
