import classnames from 'classnames';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import { JsonApiCurrentUser } from '../../../../../../../@types/jsonapi.d';
import { currentRouterPathnameSelector } from '../../../../../../../selectors/router';
import { useSelector } from '../../../../../../../utils/hooks/useRedux';
import { WaterfallProps } from '../../../../@types/HeaderConfig.d';

import './MainMenuItemLink.scss';

interface MenuItemProps extends WaterfallProps {
  active: boolean;
  closeMainMenuMenu: () => void;
  currentUser: JsonApiCurrentUser;
}

function MainMenuItemLink({
  to,
  className,
  label,
  icon: IconComponent,
  active,
  closeMainMenuMenu,
  currentUser: currentUserJSONAPI,
  risingEdge,
  faillingEdge,
  notificationKey,
}: MenuItemProps) {
  const { currentUser } = useContext(CurrentUserContext);

  const [lastRefetchAt, setLastRefetchAt] = useState<number>(Date.now());
  if (
    window.location.pathname.includes('conversations/') &&
    Date.now() - lastRefetchAt > 10000
  ) {
    setLastRefetchAt(Date.now());
  }

  let notificationCount;
  if (notificationKey === 'unreadMessageCount') {
    notificationCount =
      (currentUser?.newMessagesFromMembersCount || 0) +
      (currentUser?.newMessagesFromAmbassadorsCount || 0);
  } else if (notificationKey === 'wishedProfessionalsCount') {
    notificationCount = currentUser?.wishesCount;
  }
  const currentRoute = useSelector(currentRouterPathnameSelector);
  const [previousValue, setPreviousValue] = useState<boolean | undefined>(
    undefined
  );
  const [isRisingEdge, setIsRisingEdge] = useState(false);
  useEffect(() => {
    if (typeof risingEdge === 'function') {
      const newValue = risingEdge({
        currentUser: currentUserJSONAPI,
        currentRoute,
      });
      if (
        previousValue !== undefined &&
        newValue &&
        previousValue !== newValue
      ) {
        setIsRisingEdge(true);
      }
      setPreviousValue(newValue);
    }
    if (typeof faillingEdge === 'function') {
      if (
        isRisingEdge &&
        faillingEdge({ currentUser: currentUserJSONAPI, currentRoute })
      ) {
        setIsRisingEdge(false);
      }
    }
  }, [currentUserJSONAPI, currentRoute]);
  if (!to) {
    return (
      <span className={classnames(className, 'main-menu-item-link')}>
        <span className="main-menu-item-label">{label}</span>
      </span>
    );
  }
  return (
    <Link
      to={to}
      className={classnames(className, {
        'main-menu-item-link': true,
        active,
      })}
      data-label={label}
      onClick={closeMainMenuMenu}
    >
      {IconComponent && <IconComponent />}
      <span className="main-menu-item-label">{label}</span>
      {!!notificationCount && (
        <span className="notification-count">{notificationCount}</span>
      )}
      {isRisingEdge && (
        <Loader
          type="ball-scale-multiple"
          active
        />
      )}
    </Link>
  );
}

export default MainMenuItemLink;
