import filter from 'lodash.filter';
import moment from 'moment';
import { createSelector } from 'reselect';
import { isCancelled } from '../utils/appointment';
import { mapConversation } from '../utils/conversation';
import { flattenAttributes, findRelationship } from '../utils/json-api';
import { profileContentEducationsSelector } from './api';
import {
  currentProfessionalProfileSelector,
  usersJsonApiFromState,
} from './profile';
import {
  isValidRelationships,
  messagingAppointmentsSelector,
  messagingConversationsSelector,
  usersSelector,
  allProfilesSelector,
} from './';

/**
 * Retrieve not cancelled appointments from store
 * @param {Object} state - redux store
 * @return {Array}
 */
export const notCancelledAppointmentsSelector = createSelector(
  messagingAppointmentsSelector,
  messagingConversationsSelector,
  usersSelector,
  allProfilesSelector,
  profileContentEducationsSelector,
  (appointments, conversations, users, profiles, profileContentEducations) => {
    if (appointments.length === 0) {
      return [];
    }
    return appointments
      .filter((appointment) =>
        isValidRelationships(appointment, ['conversation']),
      )
      .map((appointment) => ({
        ...flattenAttributes(appointment),
        conversation: mapConversation(
          findRelationship(appointment, conversations, 'conversation'),
          [],
          users,
          profiles,
          appointments,
          [],
          profileContentEducations,
        ),
      }))
      .filter((appointment) => !isCancelled(appointment.status));
  },
);

/**
 * Retrieve not cancel AND in futur appointments for current user
 * @param {Object} state - redux store
 * @returns {Object[]}
 */
export const notCancelledInFutureAppointmentSelector = createSelector(
  notCancelledAppointmentsSelector,
  (appointments) =>
    filter(appointments, (a) => moment(a.from).isAfter(moment())),
);

/**
 * Retrieve all appointment claims from store
 * @param {Object} state - redux store
 */
export const appointmentClaimsJsonApiSelector = ({
  api: { messagingAppointmentClaims },
}) => messagingAppointmentClaims || [];

/**
 * Extract appointment claims from current professional
 * Return empty array if not a professional
 * @param {Object} state - redux store
 * @return {Array}
 */
export const getCurrentUserPendingAppointmentClaims = createSelector(
  appointmentClaimsJsonApiSelector,
  currentProfessionalProfileSelector,
  usersJsonApiFromState,
  messagingConversationsSelector,
  allProfilesSelector,
  (
    appointmentsClaims = [],
    loggedProfessionalProfile,
    users = [],
    conversations = [],
    profiles = [],
  ) => {
    if (!loggedProfessionalProfile) {
      return [];
    }
    const appointmentClaimIds = (
      loggedProfessionalProfile?.relationships?.appointmentClaims?.data || []
    ).map(({ id }) => id);
    const pendingAppointmentsClaims = appointmentsClaims
      .filter(({ id, ...claim }) => {
        if (appointmentClaimIds.indexOf(id) === -1) {
          return false;
        }
        if (typeof claim?.attributes?.accepted === 'boolean') {
          return false;
        }
        return true;
      })
      .reduce((acc, claim) => {
        const conversation = findRelationship(
          claim,
          conversations,
          'conversation',
        );
        const initiator = findRelationship(conversation, users, 'initiator');
        const recipient = findRelationship(conversation, users, 'recipient');
        const initiatorProfile = findRelationship(
          conversation,
          profiles,
          'initiatorProfile',
        );
        const recipientProfile = findRelationship(
          conversation,
          profiles,
          'recipientProfile',
        );

        if (conversation !== null) {
          acc.push({
            id: claim.id,
            ...flattenAttributes(claim),
            conversation: {
              ...flattenAttributes(conversation),
              initiator: {
                id: initiator.id,
                ...initiator.attributes,
                profiles: [initiatorProfile],
                profile: initiatorProfile.attributes,
              },
              recipient: {
                id: recipient.id,
                ...recipient.attributes,
                profiles: [recipientProfile],
                profile: recipientProfile.attributes,
              },
            },
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return moment(a.from).isBefore(moment(b.from)) ? -1 : 1;
      });

    return pendingAppointmentsClaims || [];
  },
);
