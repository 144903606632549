import { defineMessages } from 'react-intl';

const switchTranslations = defineMessages({
  title: {
    id: 'SwitchTranslations.title',
    defaultMessage: 'Espace :',
  },
  search: {
    id: 'SwitchTranslations.search',
    defaultMessage: 'Membre',
  },
  share: {
    id: 'SwitchTranslations.share',
    defaultMessage: 'Ambassadeur',
  },
});

export default switchTranslations;
