import { matchPath } from 'react-router';
import { SchoolAssignmentsRoutes } from 'routes';
import { JsonApiCurrentUser } from '../../../../@types/jsonapi.d';
import { isSchoolCoach } from '../../../../utils/typed/user';
import { isAmbassador, isMentor, isStudent } from '../../../../utils/user';

export function isDisplaySchoolCoachHeader(
  pathname: string,
  currentUser: JsonApiCurrentUser,
): boolean {
  if (!isSchoolCoach(currentUser)) {
    return false;
  }
  if (
    isSchoolCoach(currentUser) &&
    !isMentor(currentUser) &&
    !isAmbassador(currentUser) &&
    !isStudent(currentUser)
  ) {
    return true;
  }
  if (
    Object.values(SchoolAssignmentsRoutes).find((route) =>
      matchPath(pathname, route),
    )
  ) {
    return true;
  }
  return false;
}
