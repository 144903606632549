import {
  CalendarIcon,
  DashboardIcon,
  MessageIcon,
  SearchIcon,
  WishIcon,
} from '@myjobglasses/dsm';
import { getAccountUrl } from 'utils/redirection.util';
import ExerciceIcon from '../../../../../design-system/icons/ExerciceIcon';
import Routes from '../../../../../routes';
import { numberOfMissingInformationInProfileSelector } from '../../../../../selectors/profile';
import { t } from '../../../../../utils/translate';
import { getUserMainProfile } from '../../../../../utils/user';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './student.translations';
import translationsProfessional from '../professional/professional.translations';

/* eslint-disable */
const accountEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL || '';
const vocationEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_SELF_SERVICE_FRONTEND_URL || '';
const hermesEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || '';

/* eslint-enable */

const config: { main: HeaderConfigItem[]; connected: HeaderConfigItem[] } = {
  main: [
    {
      component: MainMenuItem,
      type: 'link',
      key: 'search',
      className: 'main-menu-item-link-blue',
      to: Routes.search,
      label: t(translations.search),
      icon: SearchIcon,
      risingEdge: ({ currentUser }) => {
        if (!currentUser) {
          return false;
        }
        const currentProfile = getUserMainProfile(currentUser);
        return (
          (currentProfile?.profileCompletion?.fieldsets?.base
            ?.completionPercentage || 0) >= 100
        );
      },
      faillingEdge: ({ currentRoute }) => currentRoute === Routes.search,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'dashboard',
      to: Routes.dashboard,
      label: t(translations.dashboard),
      icon: DashboardIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'wishlist',
      to: Routes.studentWishlist,
      label: t(translations.favorites),
      icon: WishIcon,
      notificationKey: 'wishedProfessionalsCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'affiliations',
      to: Routes.studentAffiliationList,
      label: t(translations.affiliations),
      icon: ExerciceIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'conversations',
      to: Routes.conversations,
      label: t(translations.conversations),
      icon: MessageIcon,
      notificationKey: 'unreadMessageCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'appointments',
      to: Routes.appointments,
      label: t(translations.appointments),
      icon: CalendarIcon,
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'profile',
      to: getAccountUrl('/profile'),
      label: t(translations.profile),
      isNotificationSelector: numberOfMissingInformationInProfileSelector,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: t(translations.settings),
    },
    {
      key: 'mjg-admin-new-bo',
      component: UsernavItem,
      type: 'external-link',
      to: `${hermesEndpoint}/admin`,
      label: t(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: t(translations.logout),
    },
    {
      component: UsernavItem,
      type: 'switch-account',
      key: 'switch-account',
      to: Routes.companyAdminBase,
      label: t(translationsProfessional.employeeMode),
      condition: ({ currentUser }) => !!currentUser?.companyAdminProfiles
        && currentUser.companyAdminProfiles.length > 0,
    },
    {
      component: UsernavItem,
      type: 'external-switch',
      key: 'switch-vocation',
      to: vocationEndpoint,
      label: t(translations.schoolCoachMode),
      condition: ({ currentUser }) => !!currentUser && currentUser.isCoach,
    },
  ],
};

export default config;
