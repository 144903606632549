import React from 'react';
import PropTypes from 'prop-types';
import UsernavConnected from '../usernav-connected/UsernavConnected';

const UsernavMenu = ({ currentUser, config }) => (
  <div className="username-menu">
    {currentUser && !currentUser?.mustConfirmEmail ? (
      <UsernavConnected
        currentUser={currentUser}
        config={config}
      />
    ) : null}
  </div>
);

UsernavMenu.propTypes = {
  currentUser: PropTypes.shape({
    mustConfirmEmail: PropTypes.bool,
  }),
  config: PropTypes.arrayOf(PropTypes.shape()),
};
UsernavMenu.defaultProps = {
  currentUser: undefined,
  config: undefined,
};

export default UsernavMenu;
