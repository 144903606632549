import React from 'react';

const MessageIcon = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 24 26"
  >
    <path
      className="hover"
      fill="#e0ca4b"
      d="M22.5,20.5h-21c-0.6,0-1-0.5-1-1v-13c0-0.6,0.4-1,1-1h21c0.5,0,1,0.4,1,1v13C23.5,20,23,20.5,22.5,20.5z"
    />

    <g>
      <path
        fill="#0E0E11"
        d="M7.1,13.1l-5,5c-0.2,0.2-0.2,0.5,0,0.7C2.2,19,2.4,19,2.5,19s0.3,0,0.4-0.1l5-5c0.2-0.2,0.2-0.5,0-0.7S7.3,13,7.1,13.1z"
      />
      <path
        fill="#0E0E11"
        d="M16.9,13.1c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l5,5c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L16.9,13.1z"
      />
      <path
        fill="#0E0E11"
        d="M22.5,5h-21C0.7,5,0,5.7,0,6.5v13C0,20.3,0.7,21,1.5,21h21c0.8,0,1.5-0.7,1.5-1.5v-13C24,5.7,23.3,5,22.5,5z M22.1,6L12,12.9L1.9,6H22.1z M22.5,20h-21C1.2,20,1,19.8,1,19.5V6.6l10.7,7.3C11.8,14,11.9,14,12,14s0.2,0,0.3-0.1L23,6.6v12.9C23,19.8,22.8,20,22.5,20z"
      />
    </g>
  </svg>
);

export default MessageIcon;
