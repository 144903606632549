import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from '../../../../../../../utils/hooks/useRedux';
import UsernavContext from '../../../../context/UsernavContext';

const UsernavItemAction = ({ onClick, label, className }) => {
  const dispatch = useDispatch();
  const { closeUsernavMenu } = useContext(UsernavContext);
  function handleClick(e) {
    onClick(dispatch, e);
    closeUsernavMenu();
  }
  return (
    <button
      type="button"
      onClick={handleClick}
      className={className}
    >
      {label}
    </button>
  );
};

UsernavItemAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
UsernavItemAction.defaultProps = {
  className: undefined,
};

export default UsernavItemAction;
