import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Routes from '../../../../../routes';
import BreakpointDisplay from '../breakpoint-display/BreakpointDisplay';

import './LogoZone.scss';

const LogoZone = ({ className, variant }) => (
  <div className={classnames('logo-zone', className)}>
    <Link to={Routes.home}>
      {variant === 'connect' ? (
        <img
          className={classnames('white-logo', className)}
          src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
          alt=""
        />
      ) : (
        <img
          src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
          alt=""
        />
      )}
    </Link>
    <BreakpointDisplay />
  </div>
);

LogoZone.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['connect']),
};
LogoZone.defaultProps = {
  className: undefined,
  variant: undefined,
};

export default LogoZone;
