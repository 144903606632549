import { put, select, take, race } from 'redux-saga/effects';
import { getProfile } from '../../../../../actions/profile';
import { getUpcomingAppointment } from '../../../../../actions/appointments';
import { setContainerLoadState } from '../../../../../actions/container-load-state';
import { AUTO_SIGNIN_SUCCESS } from '../../../../../actions/signin';
import { BOOTSTRAPPING_SUCCESS } from '../../../../../actions/user';
import { userSelector, currentUserSelector } from '../../../../../selectors';
import ApiResource from '../../../../../api-resources';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../../../utils/actions';

export function* hydrateDashboard() {
  yield put(setContainerLoadState('dashboard-view', { loading: true }));

  const user = yield select(currentUserSelector);
  if (!user) {
    yield take([AUTO_SIGNIN_SUCCESS, BOOTSTRAPPING_SUCCESS]);
  }
  yield put(getProfile());

  const { upcomingAppointmentsCount } = yield select(userSelector);
  if (upcomingAppointmentsCount >= 0) {
    yield put(getUpcomingAppointment());
  }

  const { success, error } = yield race({
    success: take([
      createSuccessActionType('READ', ApiResource.USER),
      createSuccessActionType('READ', ApiResource.MESSAGING_APPOINTMENT),
    ]),
    error: take([
      createErrorActionType('READ', ApiResource.USER),
      createErrorActionType('READ', ApiResource.MESSAGING_APPOINTMENT),
    ]),
  });

  if (success) {
    yield put(
      setContainerLoadState('dashboard-view', { loaded: true, success: true }),
    );
  } else {
    yield put(setContainerLoadState('dashboard-view', { error }));
  }
}
