import React from 'react';
import PropTypes from 'prop-types';
import { compose, withContext } from 'recompose';
import { Col, Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../../scenes/layout/footer/Footer';
import Header from '../../scenes/layout/header/Header';
import {
  flowSelector,
  isFooterVisibleSelector,
  userSelector,
} from '../../selectors';

import './Layout.scss';

const SHOW_INFORMATION_BANNER = false;

const Layout = ({ flow, isFooterVisible, children }) => (
  <Grid
    className="layout"
    fluid
  >
    <Header
      flow={flow}
      informationBanner={SHOW_INFORMATION_BANNER}
    />
    <Row
      className={classNames('view', {
        'view--with-banner': SHOW_INFORMATION_BANNER,
      })}
    >
      <Col md={12}>{children}</Col>
    </Row>
    {isFooterVisible && <Footer />}
  </Grid>
);

Layout.propTypes = {
  flow: PropTypes.string,
  isFooterVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default withRouter(
  compose(
    connect((state) => ({
      ...state,
      user: userSelector(state),
      isFooterVisible: isFooterVisibleSelector(state),
      flow: flowSelector(state),
    })),
    withContext(
      {
        user: PropTypes.object,
      },
      ({ user }) => ({
        user,
      }),
    ),
  )(Layout),
);
