import { useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import Routes, {
  CompanyAdminRoutes,
  MJGAdminRoutes,
} from 'routes';
import {
  JsonApiCurrentUser,
  ProfileType,
} from '../../../../@types/jsonapi.d';
import { HeaderConfigItem } from '../@types/HeaderConfig.d';
import companyAdminConfig from '../config/companyAdmin/companyAdmin';
import professionalConfig from '../config/professional/professional';
import studentConfig from '../config/student/student';
import visitorConfig from '../config/visitor/visitor';
import { isDisplayCompanyAdminHeader } from './company-admin';
import { isDisplaySchoolCoachHeader } from './school-coach';
import { isDisplayMJGAdminHeader } from './mjg-admin';
import styles from '../Header.module.scss';
import { CompanyViewParams } from '../../../../@types/routes-params.d';

function addCompanyAdminCompanySwitches(
  user: JsonApiCurrentUser,
  currentCompanyId?: string,
) {
  if (!companyAdminConfig.connected) {
    return companyAdminConfig;
  }

  const switchIndex: number = companyAdminConfig.connected
    .map((e) => e.type === 'company-switch')
    .indexOf(true);
  if (switchIndex === -1) {
    return companyAdminConfig;
  }

  const userCompanyAdminProfiles = user.profiles.filter(
    (profile) => profile.type === ProfileType.companyAdmin,
  );

  const switchConfig = companyAdminConfig.connected[switchIndex];
  const companiesSwitchConfigs = userCompanyAdminProfiles
    .filter(
      (profile) => profile.companyId && profile.companyId !== currentCompanyId,
    )
    .map(
      (profile): HeaderConfigItem => ({
        component: switchConfig.component,
        type: 'switch-account',
        key: profile.companyId || '',
        to: generatePath(Routes.companyAdminDashboardWithId, {
          companyid: profile.companyId,
        }),
        label: profile.companyName,
      }),
    );

  const configWithSwitches: {
    main: HeaderConfigItem[];
    connected: HeaderConfigItem[];
  } = {
    main: companyAdminConfig.main,
    connected: [
      ...companyAdminConfig.connected.slice(0, switchIndex),
      ...companiesSwitchConfigs,
      ...companyAdminConfig.connected.slice(switchIndex + 1),
    ],
  };

  return configWithSwitches;
}

/**
 * Get the header config for currentUser
 * @param {Object} user
 * @return {{ main: Object[], connected: Object[] }}
 */
interface HeaderConfigMixte {
  main: HeaderConfigItem[];
  connected?: HeaderConfigItem[];
}
export function getHeaderProfileConfiguration(
  user: JsonApiCurrentUser,
  route: string,
): HeaderConfigMixte {
  const { switchState } = useContext(CurrentUserContext);

  const displayCompanyAdminHeader = isDisplayCompanyAdminHeader(route, user);
  const displayMJGAdminHeader = isDisplayMJGAdminHeader(route, user);
  const match = useRouteMatch<CompanyViewParams>({
    path: [
      ...Object.values(CompanyAdminRoutes),
      ...Object.values(MJGAdminRoutes),
    ],
    exact: true,
  });

  const memoCompanyAdminConfig = useMemo(() => {
    if (!user || !displayCompanyAdminHeader) { return undefined; }
    return addCompanyAdminCompanySwitches(user, match?.params?.companyid);
  }, [user, displayCompanyAdminHeader]);

  if (user) {
    if (displayCompanyAdminHeader && memoCompanyAdminConfig) {
      return memoCompanyAdminConfig;
    }
    if (displayMJGAdminHeader) {
      return professionalConfig;
    }

    return switchState === 'member' ? studentConfig : professionalConfig;
  }
  return visitorConfig;
}

export function getHeaderVariant(user: JsonApiCurrentUser, route: string) {
  if (
    isDisplayCompanyAdminHeader(route, user) ||
    isDisplaySchoolCoachHeader(route, user) ||
    isDisplayMJGAdminHeader(route, user)
  ) {
    return styles.blueVariant;
  }
  return undefined;
}

export function getHeaderLogoVariant(user: JsonApiCurrentUser, route: string) {
  if (
    isDisplayCompanyAdminHeader(route, user) ||
    isDisplayMJGAdminHeader(route, user) ||
    isDisplaySchoolCoachHeader(route, user)
  ) {
    return 'connect';
  }
  return undefined;
}
