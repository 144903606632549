import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UsernavContext from '../../../../context/UsernavContext';
import { useSelector } from '../../../../../../../utils/hooks/useRedux';

const UsernavItemLink = ({ to, label, className, isNotificationSelector }) => {
  const { closeUsernavMenu } = useContext(UsernavContext);
  const hasNotifications = useSelector(isNotificationSelector);
  function onClick() {
    closeUsernavMenu();
  }
  return (
    <Link
      to={to}
      className={className}
      onClick={onClick}
    >
      {label}
      {!!hasNotifications && <span className="notification-bubble" />}
    </Link>
  );
};

UsernavItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isNotificationSelector: PropTypes.func,
};
UsernavItemLink.defaultProps = {
  className: undefined,
  isNotificationSelector: () => false,
};

export default UsernavItemLink;
