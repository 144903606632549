import { defineMessages } from 'react-intl';

export const CompleteInformationsModalTranslations = defineMessages({
  title: {
    id: 'CompleteInformationsModalTranslations.title',
    defaultMessage: 'Partager son expérience',
  },
  description: {
    id: 'CompleteInformationsModalTranslations.description',
    defaultMessage:
      'Afin de pouvoir partager votre expérience en tant qu’Ambassadeur deux petites étapes sont nécessaires :',
  },
  listItem1: {
    id: 'CompleteInformationsModalTranslations.listItem1',
    defaultMessage:
      'Complétez les sections non optionnelles de votre profil notamment la section “Pour partager”',
  },
  listItem2: {
    id: 'CompleteInformationsModalTranslations.listItem2',
    defaultMessage: 'Demandez la modération de votre profil',
  },
  later: {
    id: 'CompleteInformationsModalTranslations.later',
    defaultMessage: 'Peut-être plus tard',
  },
  completeMyProfile: {
    id: 'CompleteInformationsModalTranslations.completeMyProfile',
    defaultMessage: 'Compléter mon profil',
  },
});
