import ApiResource from '../api-resources';
import { createResource, readResource, readListResource } from './api';

export const SUBMIT_CONVERSATION_CREATION =
  '@@conversations/SUBMIT_CONVERSATION_CREATION';

/**
 * Request conversation creation on server
 * @param {Object} params
 * @param {String} params.profileId - recipient ID
 * @param {String} params.profileType - recipient profile type ['mentor', 'company/employee']
 * @param {String} params.text - Content of message
 */
export const createConversation = ({ profileId, profileType, text }) =>
  createResource({
    resourceUri: '/messaging/conversations',
    data: {
      type: ApiResource.MESSAGING_CONVERSATION,
      attributes: {
        recipient_profile_id: profileId,
        recipient_profile_type:
          profileType === 'mentor' ? 'Mentor' : 'Company::Employee',
        text,
      },
    },
  });

/**
 * Request professional aviabilities (at request time)
 * @param {Object} params
 * @param {String} params.profileId - recipient profile ID
 * @param {String} params.profileType - recipient profile type (mentor / employee)
 */
export const requestProfessionalAvailabilities = ({ profileId, profileType }) =>
  readResource({
    resourceUri: '/messaging/conversations/new',
    data: {
      type: 'messaging/new_conversation',
    },
    params: {
      recipient_profile_id: profileId,
      recipient_profile_type: profileType,
    },
  });

/**
 * Request to submit a new conversation
 * @param {String} message
 */
export const submitConversationCreation = (message) => ({
  type: SUBMIT_CONVERSATION_CREATION,
  message,
});

/**
 * Retrieve conversations for current user
 * @param {Object} params
 * @param {Integer} params.size - Number of items to request
 * @param {Integer} params.page - Page to start
 */
export const getNotRepliedConversations = (
  { size, page } = { page: 1, size: 8 },
) =>
  readListResource({
    resourceUri: '/messaging/conversations',
    data: {
      type: ApiResource.MESSAGING_NOT_REPLIED_CONVERSATION,
    },
    params: {
      'filter[with_first_reply_from_professional]': false,
      include: [
        'last_sender',
        'messages.sender',
        'initiator',
        'recipient',
        'initiator_profile.user',
        'initiator_profile.organization',
        'recipient_profile.user',
        'recipient_profile.organization',
      ].join(','),
      'page[size]': size,
      'page[number]': page,
      'stats[total]': 'count',
    },
  });

/**
 * Retrieve a single conversation
 * @param {Object} params
 * @param {String} params.id
 */
export const getConversation = ({ id }) =>
  readResource({
    resourceUri: `/messaging/conversations/${id}`,
    data: {
      type: 'messaging/conversation',
    },
    params: {
      include: [
        'messages.sender',
        'initiator',
        'recipient',
        'initiator_profile.user',
        'initiator_profile.educations',
        'initiator_profile.organization',
        'initiator_profile.internships',
        'recipient_profile.user',
        'recipient_profile.organization',
        'appointments.conversation',
        'appointment_claims',
      ].join(','),
    },
  });
