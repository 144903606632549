import React from 'react';

interface SchoolCoachAvatarProps {
  className?: string;
}
function SchoolCoachAvatar({ className }: SchoolCoachAvatarProps) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 144 144"
      className={className}
    >
      <path
        fill="#ced1d8"
        d="M107.622,102.346l-0.068-0.117l-0.005,0.003c-0.621-1.049-1.344-2.056-2.185-3.001
        c-3.253-3.65-7.596-5.74-12.104-6.259c-0.317-0.045-0.635-0.08-0.957-0.102c-0.089-0.006-0.178-0.009-0.267-0.013
        c-0.228-0.012-0.454-0.036-0.686-0.036H38.997c-0.581,0-1.15,0.049-1.711,0.12c-4.779,0.501-9.376,2.777-12.696,6.751
        c-0.811,0.972-1.502,2.002-2.09,3.07l-0.005-0.003l-0.069,0.126c-0.003,0.006-0.008,0.013-0.011,0.019l-7.275,13.239
        C5.661,103.951,0,88.641,0,72C0,32.236,32.236,0,72,0s72,32.236,72,72c0,20.874-8.891,39.663-23.083,52.814l-13.274-22.433
        C107.636,102.369,107.629,102.357,107.622,102.346z"
      />
      <path
        fill="#e0ca4b"
        d="M82.478,131.004l9.147,10.266C85.384,143.035,78.806,144,72,144c-10.469,0-20.405-2.252-29.379-6.269
        l5.619-6.726h34.238V131.004z"
      />
      <path
        fill="#e0ca4b"
        d="M15.141,116.144l7.275-13.239c0.003-0.007,0.008-0.013,0.011-0.019l0.069-0.126l0.005,0.003
        c0.587-1.068,1.278-2.099,2.09-3.07c3.32-3.973,7.917-6.249,12.696-6.751c0.561-0.071,1.13-0.12,1.711-0.12h52.353
        c0.232,0,0.458,0.024,0.686,0.036c0.089,0.004,0.178,0.008,0.267,0.013c0.323,0.023,0.641,0.057,0.957,0.102
        c4.508,0.518,8.851,2.609,12.104,6.259c0.842,0.945,1.565,1.952,2.185,3.001l0.005-0.003l0.068,0.117
        c0.007,0.011,0.014,0.023,0.021,0.034l13.274,22.433c-8.215,7.613-18.21,13.322-29.292,16.457l-9.147-10.266H48.241l-5.619,6.726
        C31.772,132.874,22.35,125.417,15.141,116.144z"
      />
      <path
        fill="#AA7852"
        d="M49.338,92.822c0,9.469,7.691,17.144,17.18,17.144s17.18-7.675,17.18-17.144H49.338z"
      />
      <path
        fill="#283042"
        d="M96.011,55.009c-0.01,0.008-0.01,0.008-0.01,0.008"
      />
      <polygon
        fill="#AA7852"
        points="77.493,97.672 57.449,99.219 56.353,81.122 75.588,72.992 "
      />
      <path
        fill="#0E0E11"
        d="M90.247,48.9l-3.395,0.186l-1.254-22.918l3.395-0.186c6.329-0.346,11.74,4.503,12.086,10.832l0,0
        C101.426,43.142,96.576,48.553,90.247,48.9z"
      />
      <path
        fill="#0E0E11"
        d="M63.521,72.078l-7.902,0.432c-5.012,0.274-9.297-3.566-9.571-8.578l-0.477-8.711l16.977-0.929L63.521,72.078z"
      />
      <path
        fill="#0E0E11"
        d="M87.284,48.469l-8.58,0.47L77.09,19.446l7.537-0.412c6.014-0.329,11.157,4.28,11.486,10.294l0.479,8.756
        C96.889,43.521,92.722,48.171,87.284,48.469z"
      />
      <path
        fill="#AA7852"
        d="M86.491,79.473l-30.137,1.649l-1.857-33.937c-0.565-10.323,7.346-19.149,17.668-19.714l0,0
        c9.972-0.546,18.616,6.833,19.643,16.766l2.675,25.873C94.981,74.941,91.34,79.208,86.491,79.473z"
      />
      <path
        fill="#0E0E11"
        d="M62.111,24.82l1.37-0.075l1.749,31.954l-19.52,1.068l-0.755-13.805
        C44.406,33.939,52.088,25.368,62.111,24.82z"
      />
      <path
        fill="#AA7852"
        d="M55.219,60.44l5.093-0.279l-0.279-5.093c-0.154-2.813-2.559-4.968-5.372-4.814l0,0
        c-2.813,0.154-4.968,2.559-4.814,5.372l0,0C50.001,58.438,52.406,60.593,55.219,60.44z"
      />
      <path
        fill="#0E0E11"
        d="M94.191,38.945l-19.336,1.058c-7.881,0.431-14.642-5.629-15.074-13.51c-0.205-3.75,2.669-6.957,6.419-7.162
        c3.75-0.205,6.957,2.669,7.162,6.419c0.021,0.392,0.357,0.693,0.749,0.672l19.336-1.058L94.191,38.945z"
      />
      <path
        fill="#0E0E11"
        d="M86.604,32.548l-9.62,0.526c-3.75,0.205-6.957-2.669-7.162-6.419s2.669-6.957,6.419-7.162l9.62-0.526
        c3.75-0.205,6.957,2.669,7.162,6.419S90.354,32.343,86.604,32.548z"
      />
      <ellipse
        transform="matrix(0.9942 -0.1072 0.1072 0.9942 -4.7652 9.4332)"
        fill="#0E0E11"
        cx="85.356"
        cy="49.038"
        rx="1.395"
        ry="1.8"
      />
      <ellipse
        transform="matrix(0.9942 -0.1072 0.1072 0.9942 -4.9725 8.1576)"
        fill="#0E0E11"
        cx="73.389"
        cy="50.329"
        rx="1.395"
        ry="1.8"
      />
      <line
        fill="none"
        stroke="#0E0E11"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="74.905"
        y1="45.297"
        x2="70.958"
        y2="46.8"
      />
      <line
        fill="none"
        stroke="#0E0E11"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="87.914"
        y1="45.429"
        x2="82.267"
        y2="44.56"
      />
      <path
        fill="#FFFFFF"
        d="M86.395,65.866c0.174,2.719-1.89,5.065-4.609,5.239c-2.719,0.174-5.065-1.89-5.239-4.609L86.395,65.866z"
      />
    </svg>
  );
}

export default SchoolCoachAvatar;
