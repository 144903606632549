import { defineMessages } from 'react-intl';

export const InformationBannerTranslations = defineMessages({
  text: {
    id: 'InformationBanner.text',
    defaultMessage:
      '✨ De nouvelles fonctionnalités vous attendent sur My Job Glasses ! ✨',
  },
  link: {
    id: 'InformationBanner.link',
    defaultMessage: 'Découvrir les nouveautés.',
  },
});
