import { getAccountUrl } from 'utils/redirection.util';
import CalendarIcon from '../../../../../design-system/icons/CalendarIcon';
import DashboardIcon from '../../../../../design-system/icons/DashboardIcon';
import MessageIcon from '../../../../../design-system/icons/MessageIcon';
import Routes from '../../../../../routes';
import { numberOfMissingInformationInProfileSelector } from '../../../../../selectors/profile';
import { t } from '../../../../../utils/translate';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './professional.translations';

/* eslint-disable */
const accountEndpoint =
  window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL || '';
const hermesEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || '';
const vocationEndpoint =
  window.__RUNTIME_CONFIG__.REACT_APP_SELF_SERVICE_FRONTEND_URL || '';
/* eslint-enable */

const config: { main: HeaderConfigItem[]; connected: HeaderConfigItem[] } = {
  main: [
    {
      component: MainMenuItem,
      type: 'score',
      key: 'score',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'dashboard',
      to: Routes.dashboard,
      label: t(translations.dashboard),
      icon: DashboardIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'conversations',
      to: Routes.conversations,
      label: t(translations.conversations),
      icon: MessageIcon,
      notificationKey: 'unreadMessageCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'appointments',
      to: Routes.appointments,
      label: t(translations.appointments),
      icon: CalendarIcon,
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'profile',
      to: getAccountUrl('/profile'),
      label: t(translations.profile),
      isNotificationSelector: numberOfMissingInformationInProfileSelector,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: t(translations.settings),
    },
    {
      key: 'mjg-admin-new-bo',
      component: UsernavItem,
      type: 'external-link',
      to: `${hermesEndpoint}/admin`,
      label: t(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: t(translations.logout),
    },
    {
      component: UsernavItem,
      type: 'switch-account',
      key: 'switch-account',
      to: Routes.companyAdminBase,
      label: t(translations.employeeMode),
      condition: ({ currentUser }) => !!currentUser?.companyAdminProfiles
        && currentUser.companyAdminProfiles.length > 0,
    },
    {
      component: UsernavItem,
      type: 'external-switch',
      key: 'switch-vocation',
      to: vocationEndpoint,
      label: t(translations.schoolCoachMode),
      condition: ({ currentUser }) => !!currentUser && currentUser.isCoach,
    },
  ],
};

export default config;
