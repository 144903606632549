import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'Header.config.companyAdmin.dashboard',
    defaultMessage: 'Dashboard',
  },
  ambassador: {
    id: 'Header.config.companyAdmin.ambassador',
    defaultMessage: 'Ambassadeurs',
    description: 'item deconnexion dans la usernav',
  },
  appointments: {
    id: 'Header.config.companyAdmin.appointments',
    defaultMessage: 'rendez-vous',
  },
  configuration: {
    id: 'Header.config.companyAdmin.configuration',
    defaultMessage: 'Configuration',
    description: 'item config dans la usernav',
  },
  settings: {
    id: 'Header.config.companyAdmin.settings',
    defaultMessage: 'Paramètres',
    description: 'item setting dans la usernav',
  },
  logout: {
    id: 'Header.config.companyAdmin.logout',
    defaultMessage: 'Se déconnecter',
    description: 'item deconnexion dans la usernav',
  },
  employeeMode: {
    id: 'companyAdmin.employeeMode',
    defaultMessage: 'Mode Ambassadeur',
    description: 'label mode Ambassadeur',
  },
});
