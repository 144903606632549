import { delay, put, race, select, take } from 'redux-saga/effects';
import { getAppointmentsList } from '../../../../actions/appointments';
import { setContainerLoadState } from '../../../../actions/container-load-state';
import ApiResource from '../../../../api-resources';
import { notCancelledInFutureAppointmentSelector } from '../../../../selectors/appointments';
import {
  createErrorActionType,
  createSuccessActionType,
} from '../../../../utils/actions';

export function* hydrateSoftDeletionModal() {
  yield put(setContainerLoadState('soft-deletion-modal', { loading: true }));
  const remainingAppointmentInFuture = yield select(
    notCancelledInFutureAppointmentSelector,
  );
  if (remainingAppointmentInFuture.length <= 0) {
    yield put(getAppointmentsList());
    yield race({
      success: take(
        createSuccessActionType('READ_LIST', ApiResource.MESSAGING_APPOINTMENT),
      ),
      error: take(
        createErrorActionType('READ', ApiResource.MESSAGING_APPOINTMENT),
      ),
      fallback: delay(5000),
    });
  }
  yield put(
    setContainerLoadState('soft-deletion-modal', {
      loading: false,
      loaded: true,
    }),
  );
}
