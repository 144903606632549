import React from 'react';

const CalendarIcon = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
  >
    <path
      className="hover"
      fill="#e0ca4b"
      d="M22.5,4.5h-4v-1c0-0.5-0.5-1-1-1s-1,0.5-1,1v1h-9v-1c0-0.5-0.5-1-1-1s-1,0.5-1,1v1h-4c-0.5,0-1,0.5-1,1.1v14.7c0,0.6,0.5,1.1,1,1.1h21c0.5,0,1-0.5,1-1.1V5.6C23.5,5,23,4.5,22.5,4.5z"
    />
    <g>
      <path
        fill="0E0E11"
        d="M22.5,4H19V3.5C19,2.7,18.3,2,17.5,2S16,2.7,16,3.5V4H8V3.5C8,2.7,7.3,2,6.5,2S5,2.7,5,3.5V4H1.5C0.7,4,0,4.7,0,5.6v14.7c0,1,0.7,1.7,1.5,1.7h21c0.8,0,1.5-0.7,1.5-1.6V5.6C24,4.7,23.3,4,22.5,4z M17,3.5C17,3.2,17.2,3,17.5,3S18,3.2,18,3.5v3C18,6.8,17.8,7,17.5,7S17,6.8,17,6.5V3.5z M6,3.5C6,3.2,6.2,3,6.5,3S7,3.2,7,3.5v3C7,6.8,6.8,7,6.5,7S6,6.8,6,6.5V3.5z M23,20.4c0,0.3-0.2,0.6-0.5,0.6h-21C1.2,21,1,20.7,1,20.4V5.6C1,5.3,1.2,5,1.5,5H5v1.5C5,7.3,5.7,8,6.5,8S8,7.3,8,6.5V5h8v1.5C16,7.3,16.7,8,17.5,8S19,7.3,19,6.5V5h3.5C22.8,5,23,5.3,23,5.6V20.4z"
      />
      <path
        fill="0E0E11"
        d="M18.5,16h-3c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h3c0.8,0,1.5-0.7,1.5-1.5S19.3,16,18.5,16z M18.5,18h-3c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5S18.8,18,18.5,18z"
      />
    </g>
  </svg>
);

export default CalendarIcon;
