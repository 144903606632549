import React from 'react';
import { gamificationScoreTotalSelector } from '../../../../../../user/dashboard/professionals/selector/dashboard';
import { useSelector } from '../../../../../../../utils/hooks/useRedux';
import { translateNumber } from '../../../../../../../utils/translate';

import './MainMenuItemScore.scss';

const MainMenuItemScore = () => {
  const gamificationScoreTotal = useSelector(gamificationScoreTotalSelector);
  if (!gamificationScoreTotal) {
    return null;
  }
  return (
    <span className="main-menu-item-score">
      {`${translateNumber(gamificationScoreTotal)} pts`}
    </span>
  );
};

export default MainMenuItemScore;
