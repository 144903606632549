import { matchPath } from 'react-router';
import { MJGAdminRoutes } from 'routes';
import { JsonApiCurrentUser } from '../../../../@types/jsonapi.d';
import { isMJGAdmin } from '../../../../utils/typed/user';
import { isAmbassador, isMentor, isStudent } from '../../../../utils/user';

export function isDisplayMJGAdminHeader(
  pathname: string,
  currentUser: JsonApiCurrentUser,
): boolean {
  if (!isMJGAdmin(currentUser)) {
    return false;
  }
  if (
    isMJGAdmin(currentUser) &&
    !isMentor(currentUser) &&
    !isAmbassador(currentUser) &&
    !isStudent(currentUser)
  ) {
    return true;
  }
  if (matchPath(pathname, MJGAdminRoutes.mjgAdminCompanies)) {
    return true;
  }
  return false;
}
