import { createSelector } from 'reselect';
import merge from 'lodash.merge';
import filter from 'lodash.filter';
import { fetchRelationshipID, findAllRelationships } from '../utils/json-api';
import {
  messagingConversationsSelector,
  userSelector,
  isValidRelationships,
  currentUserSelector,
} from './index';

/****** Conversation-related selectors ****/
export const ownConversationsJsonapiSelector = createSelector(
  messagingConversationsSelector,
  userSelector,
  (conversations, user) =>
    filter(
      conversations,
      (conversation) =>
        (isValidRelationships(conversation, ['initiator']) &&
          conversation.relationships.initiator.data.id === user.id) ||
        (isValidRelationships(conversation, ['recipient']) &&
          conversation.relationships.recipient.data.id === user.id),
    ),
);

/**** Appointment review related selectors ****/

export const userTaskRateSeekersJsonapiSelector = ({
  api: { userTaskRateSeekers },
}) => userTaskRateSeekers || [];
export const userTaskLikeProfessionalsJsonapiSelector = ({
  api: { userTaskLikeProfessionals },
}) => userTaskLikeProfessionals || [];

/* Returns review tasks of current user */
export const ownReviewTasksModelSelector = createSelector(
  currentUserSelector,
  userTaskRateSeekersJsonapiSelector,
  userTaskLikeProfessionalsJsonapiSelector,
  (user, rateSeekerTasks, likeProfessionalTasks) => {
    const userRateSeekerTasks = findAllRelationships(
      user,
      rateSeekerTasks,
      'reviewAppointmentTasks',
    );
    const userLikeProfessionaTasks = findAllRelationships(
      user,
      likeProfessionalTasks,
      'reviewAppointmentTasks',
    );

    return merge(userRateSeekerTasks, userLikeProfessionaTasks).map((task) => ({
      id: task.id,
      ...task.attributes,
      appointmentId: fetchRelationshipID(task, 'appointment'),
    }));
  },
);

/* Returns tasks marked as TODO */
export const ownPendingAppointmentReviewTasksModelSelector = createSelector(
  ownReviewTasksModelSelector,
  (reviewTasks) => {
    return filter(reviewTasks, (t) => t.todo) || [];
  },
);
