import React from 'react';

const ExerciceIcon = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
  >
    <g className="hover">
      <path
        fill="#e0ca4b"
        d="M23.1,19v-0.5c0-0.2-0.1-0.3-0.3-0.3h-5.3V2.5h-17v15.8h0.3c-0.1,0.1-0.1,0.1-0.1,0.2V19c0,0.2,0.1,0.3,0.3,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.6,0.5,1.1,1.1,1.1h20.3c0.6,0,1.1-0.5,1.1-1.1c0-0.3-0.1-0.6-0.3-0.8C23,19.3,23.1,19.2,23.1,19z"
      />
    </g>
    <g>
      <path
        fill="#0E0E11"
        d="M23.5,18H18V2.5C18,2.2,17.8,2,17.5,2h-17C0.2,2,0,2.2,0,2.5v15.8c0,2,1.4,3.6,3.1,3.7c0.1,0,0.1,0,0.2,0h17.4c2,0,3.3-1.4,3.3-3.5C24,18.2,23.8,18,23.5,18z M1,18.3V3h16v15H5.5C5.2,18,5,18.2,5,18.5C5,21,3.8,21,3.3,21C2.1,21,1,19.8,1,18.3z M20.7,21H5.4c0.3-0.5,0.5-1.1,0.6-2h17C22.8,20.3,22,21,20.7,21z"
      />
      <path
        fill="#0E0E11"
        d="M3.5,13.5h11c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-11C3.2,12.5,3,12.7,3,13S3.2,13.5,3.5,13.5z"
      />
      <path
        fill="#0E0E11"
        d="M3.5,6h11C14.8,6,15,5.8,15,5.5S14.8,5,14.5,5h-11C3.2,5,3,5.2,3,5.5S3.2,6,3.5,6z"
      />
      <path
        fill="#0E0E11"
        d="M3.5,8.5h11C14.8,8.5,15,8.3,15,8s-0.2-0.5-0.5-0.5h-11C3.2,7.5,3,7.7,3,8S3.2,8.5,3.5,8.5z"
      />
      <path
        fill="#0E0E11"
        d="M3.5,11h11c0.3,0,0.5-0.2,0.5-0.5S14.8,10,14.5,10h-11C3.2,10,3,10.2,3,10.5S3.2,11,3.5,11z"
      />
      <path
        fill="#0E0E11"
        d="M14.5,16c0.3,0,0.5-0.2,0.5-0.5S14.8,15,14.5,15h-11C3.2,15,3,15.2,3,15.5S3.2,16,3.5,16H14.5z"
      />
    </g>
  </svg>
);

ExerciceIcon.propTypes = {};

export default ExerciceIcon;
