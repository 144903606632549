import React from 'react';
import Modal from 'components/modal/Modal';
import { t } from 'utils/translate';
import { getAccountUrl } from 'utils/redirection.util';
import { CompleteInformationsModalTranslations } from './CompleteInformationsModal.translations';

type CompleteInformationsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CompleteInformationsModal({
  isOpen,
  onClose,
}: CompleteInformationsModalProps) {
  return (
    <Modal
      {...{ isOpen, onClose }}
      title={t(CompleteInformationsModalTranslations.title)}
      iconName="HeartHandDark"
      primaryButton={{
        label: t(CompleteInformationsModalTranslations.completeMyProfile),
        onClick: () => {
          // eslint-disable-next-line no-underscore-dangle
          window.location.href = getAccountUrl('/profile');
          onClose();
        },
        iconName: 'ArrowRight',
      }}
      secondaryButton={{
        label: t(CompleteInformationsModalTranslations.later),
        onClick: onClose,
      }}
    >
      <p>
        {t(CompleteInformationsModalTranslations.description)}

        <ul>
          <li>{t(CompleteInformationsModalTranslations.listItem1)}</li>
          <li>{t(CompleteInformationsModalTranslations.listItem2)}</li>
        </ul>
      </p>
    </Modal>
  );
}
