import { push } from 'connected-react-router';
import mapKeys from 'lodash.mapkeys';
import isEmpty from 'lodash.isempty';
import { defineMessages } from 'react-intl';
import { matchPath } from 'react-router';
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  getConversation,
  getNotRepliedConversations,
} from '../actions/conversations';
import { displayToastr } from '../actions/toastr';
import {
  getReadListSuccessActionType,
  createSuccessActionType,
  createRequestActionType,
  createErrorActionType,
  getReadSuccessActionType,
  getReadErrorActionType,
} from '../utils/actions';
import { readResourceSuccess, readResourceError } from '../actions/api';
import { buildIndex } from '../actions/api-indexes';
import { setContainerLoadState } from '../actions/container-load-state';
import ApiResource from '../api-resources';
import Routes from '../routes';
import { getMessageTemplateList } from '../scenes/interactions/conversations/actions/message-templates';
import { messageTemplatesFromJsonApi } from '../scenes/interactions/conversations/selectors/message-templates';
import { currentRouterPathnameSelector } from '../selectors/router';
import { HaveProfessionalCookie } from '../utils/typed/user';
import { extractErrorsFromPayload } from '../utils/json-api';
import { t } from '../utils/translate';
import { getHeaders, get } from './api';
import { claimAppointmentWatcher } from './appointments';
import { runDownload } from './download';

export const conversationsSagaTranslations = defineMessages({
  unprocessableEntity: {
    id: 'saga.conversations.unprocessableEntity',
    defaultMessage: 'Vous devez compléter votre template pour valider.',
    description:
      'message d erreur si le template n est pas valide, absence de titre ou inférieur à 2 caractères',
  },
  forbidden: {
    id: 'saga.conversations.forbidden',
    defaultMessage:
      'Désolé, cette fonctionnalité est réservée aux professionnels.',
    description:
      'message d erreur si le template n est pas valide, absence de titre ou inférieur à 2 caractères',
  },
  notFound: {
    id: 'saga.conversations.notFound',
    defaultMessage: 'Désolé, ce template est introuvable.',
    description:
      'message d erreur si le template n est pas valide, absence de titre ou inférieur à 2 caractères',
  },
  unknownError: {
    id: 'saga.conversations.unknownError',
    defaultMessage: "Une erreur est survenue lors de l'enregistrement",
    description:
      'message d erreur si le template n est pas valide, absence de titre ou inférieur à 2 caractères',
  },
});

export function* hydrateConversationView(id) {
  yield put(setContainerLoadState('conversation-view', { loading: true }));
  yield fork(manageMessageTemplates, id);

  yield put(getConversation({ id }));
  const { error } = yield race({
    success: take(getReadSuccessActionType(ApiResource.MESSAGING_CONVERSATION)),
    error: take(getReadErrorActionType(ApiResource.MESSAGING_CONVERSATION)),
  });
  if (error) {
    yield put(
      setContainerLoadState('conversation-view', {
        error: true,
        loading: false,
      }),
    );
    return;
  }
  yield put(
    setContainerLoadState('conversation-view', {
      success: true,
      loading: false,
    }),
  );
  yield fork(claimAppointmentWatcher);
  const autoKillService = yield takeEvery(
    '@@router/LOCATION_CHANGE',
    function* (data) {
      if (!matchPath(Routes.conversation, data?.payload?.location?.pathname)) {
        yield cancel(autoKillService);
      }
    },
  );
}

/**
 * Handle manage message templates features
 */
const messageTemplatesTasks = [];
export function* manageMessageTemplates() {
  const isProfessional = HaveProfessionalCookie();
  if (!isProfessional) {
    return;
  }
  messageTemplatesTasks.push(
    yield takeEvery(
      createRequestActionType('DELETE', ApiResource.MESSAGE_TEMPLATE),
      removeMessageTemplateFromStoreAfterDelete,
    ),
    yield takeEvery(
      [
        createErrorActionType('CREATE', ApiResource.MESSAGE_TEMPLATE),
        createErrorActionType('UPDATE', ApiResource.MESSAGE_TEMPLATE),
        createErrorActionType('DELETE', ApiResource.MESSAGE_TEMPLATE),
      ],
      handleTemplateErrors,
    ),
  );
  yield takeEvery('@@router/LOCATION_CHANGE', function* (action) {
    const pathname = action?.payload?.location?.pathname;
    if (
      !(
        matchPath(Routes.conversations, pathname) ||
        matchPath(Routes.conversation, pathname)
      )
    ) {
      yield all(messageTemplatesTasks.map((task) => cancel(task)));
    }
  });
  yield put(getMessageTemplateList());
}

/**
 * Clean redux store when succeed to remove message template
 * @param {Object} action
 * @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
 */
export function* removeMessageTemplateFromStoreAfterDelete(action) {
  const ID = action?.data?.id;
  yield take(createSuccessActionType('DELETE', ApiResource.MESSAGE_TEMPLATE));
  const messageTemplates = yield select(messageTemplatesFromJsonApi);
  const messageTemplatesIndex = messageTemplates.findIndex(
    (template) => template.id === ID,
  );
  if (messageTemplatesIndex > -1) {
    yield put({
      type: 'DIRTY_PATCH_API_DELETE',
      data: {
        messageTemplates: [
          ...messageTemplates.slice(0, messageTemplatesIndex),
          ...messageTemplates.slice(
            messageTemplatesIndex + 1,
            messageTemplates.length,
          ),
        ],
      },
    });
  }
}

export function* handleTemplateErrors(action) {
  let error = extractErrorsFromPayload(action);
  switch (error?.[0]?.name) {
    case 'forbidden':
      yield put(
        displayToastr('error', t(conversationsSagaTranslations.forbidden)),
      );
      break;
    case 'not_found':
      yield put(
        displayToastr('error', t(conversationsSagaTranslations.notFound)),
      );
      break;
    case 'unprocessable_entity':
      yield put(
        displayToastr(
          'error',
          t(conversationsSagaTranslations.unprocessableEntity),
        ),
      );
      break;
    default:
      yield put(
        displayToastr('error', t(conversationsSagaTranslations.unknownError)),
      );
      break;
  }
}

/**
 * Handle display conversation page + loading of conversation
 */
export function* hydrateDownloadConversationAppointmentIcalendarView(id) {
  const pathname = yield select(currentRouterPathnameSelector);
  const regex = Routes.downloadConversationAppointmentIcalendar
    .replace(':id', '(.*)')
    .replace(':appointment_id', '(.*)');
  const matches = pathname.match(new RegExp(regex));
  yield call(
    runDownload,
    `messaging/appointments/${matches[2]}/icalendar`,
    'appointment.ics',
  );
  yield call(hydrateConversationView, id);
  yield put(push(Routes.conversation.replace(':id', id)));
}

/**
 * LOAD and CREATE_INDEX of not replied conversations for current user
 */
export function* loadNotRepliedConversations({ size = 3, page = 1 } = {}) {
  yield put(getNotRepliedConversations({ size, page }));
  const actionPayload = yield take(
    getReadListSuccessActionType(
      ApiResource.MESSAGING_NOT_REPLIED_CONVERSATION,
    ),
  );
  const conversations = actionPayload?.data?.messagingConversation || {};
  if (!isEmpty(conversations)) {
    let total = 0;
    const meta = Object.values(actionPayload.data.meta || {});
    if (meta.length) {
      total = meta?.[0]?.meta?.stats?.total?.count;
    }
    yield put(
      buildIndex('conversationsNotReplied', Object.keys(conversations), {
        currentPage: page,
        total,
        numberOfPages: Math.ceil(total / size),
      }),
    );
  }
}

export function* readNewConversation(params) {
  const { data } = params;

  try {
    const headers = getHeaders();
    const getData = yield call(get, { ...params, headers });

    // map data to newConversations without meta
    const getDataWithMappedKeys = mapKeys(getData, (v, k) =>
      k !== 'meta' ? 'newConversations' : '',
    );

    yield put(
      readResourceSuccess({
        resourceType: data.type,
        data: getDataWithMappedKeys,
      }),
    );
  } catch (error) {
    yield put(readResourceError({ resourceType: data.type, error }));
  }
}

export function* watchReadNewConversation() {
  yield takeEvery(
    '@@api/READ_RESOURCE_MESSAGING_NEW_CONVERSATION',
    readNewConversation,
  );
}
