import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { languageWithoutRegionCode } from './locales/configure';
import generatedIntrospection from './introspection';
import typePolicies from './typePolicies';

/**
 * Use email and authenticationToken from credential key in localStorage
 * to add them to every GraphQL request
 * Add the email as a X-User-Email header
 * Add the authenticationToken as a X-User-Token header
 *
 * If the localStorage cannot be used, do nothing
 *
 * Every GraphQL request will execute this code,
 * so no need to do things on signin to handle localStorage update
 */
const authLink = setContext(() => {
  const headers = {
    'X-User-Language': languageWithoutRegionCode,
  };
  if (typeof window.localStorage?.getItem === 'function') {
    const credentials = JSON.parse(window.localStorage.getItem('credentials'));
    const email = credentials?.[0]?.attributes?.email;
    const token = credentials?.[0]?.attributes?.authenticationToken;
    if (email && token) {
      headers['X-User-Email'] = email;
      headers['X-User-Token'] = token;
    }
  }
  return { headers };
});

/**
 * Configure the /graphql endpoint
 * to handle upload : https://github.com/jaydenseric/apollo-upload-client/issues/34#issuecomment-372679857
 */
const opts = {
  // eslint-disable-next-line
  uri: window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
};

const BackendApiHTTPLink = new HttpLink({
  // eslint-disable-next-line
  uri: `${window.__RUNTIME_CONFIG__.REACT_APP_BACKEND_API_URL}/graphql`,
  credentials: 'include',
});

const uploadAndBatchHTTPLink = ApolloLink.split(
  (operation) =>
    Boolean(
      operation.query.definitions.find((d) =>
        d.variableDefinitions.find(
          (vd) =>
            vd.type?.type?.name?.value === 'Upload' ||
            vd.type?.name?.value === 'Upload',
        ),
      ),
    ),
  createUploadLink(opts),
  new BatchHttpLink(opts),
);

const client = new ApolloClient({
  // enable/disable dev tools connector
  connectToDevTools: window.__RUNTIME_CONFIG__.NODE_ENV !== 'production',

  // add middlewares to GraphQL client
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'backend-api',
    BackendApiHTTPLink,
    ApolloLink.from([authLink, uploadAndBatchHTTPLink]),
  ),

  // handle caches
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies,
  }),
});

export default client;
