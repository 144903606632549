import React from 'react';
import classnames from 'classnames';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { CompanyAdminRoutes, MJGAdminRoutes } from 'routes';
import { CompanyViewParams } from '../../../../../../../@types/routes-params.d';

import { WaterfallProps } from '../../../../@types/HeaderConfig';

interface MenuItemProps extends WaterfallProps {
  active: boolean;
  closeMainMenuMenu: () => void;
}
function MainMenuItemCompany({
  to,
  className,
  label,
  icon: IconComponent,
  active,
  closeMainMenuMenu,
}: MenuItemProps) {
  const match = useRouteMatch<CompanyViewParams>({
    path: [
      ...Object.values(CompanyAdminRoutes),
      ...Object.values(MJGAdminRoutes),
    ],
    exact: true,
  });
  if (!to || !match?.params.companyid) {
    return null;
  }
  return (
    <Link
      to={generatePath(to, { companyid: match?.params.companyid })}
      className={classnames(className, {
        'main-menu-item-link': true,
        active,
      })}
      data-label={label}
      onClick={closeMainMenuMenu}
    >
      {IconComponent && <IconComponent />}
      <span className="main-menu-item-label">{label}</span>
    </Link>
  );
}

export default MainMenuItemCompany;
