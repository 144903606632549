import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export function getPosition(address) {
  return geocodeByAddress(address).then((results) => {
    // @todo handle case with many adress
    // For exemple Station F reponds with boulevard Auriol and Passage Louise
    // For the moment, return first matching
    return getLatLng(results[0]);
  });
}
