import { matchPath } from 'react-router';
import { CompanyAdminRoutes } from '../../../../routes';
import { JsonApiCurrentUser } from '../../../../@types/jsonapi.d';
import { isCompanyAdmin } from '../../../../utils/typed/user';
import { isAmbassador, isMentor, isStudent } from '../../../../utils/user';

export function isDisplayCompanyAdminHeader(
  pathname: string,
  currentUser: JsonApiCurrentUser,
): boolean {
  if (!isCompanyAdmin(currentUser)) {
    return false;
  }
  if (
    isCompanyAdmin(currentUser) &&
    !isMentor(currentUser) &&
    !isAmbassador(currentUser) &&
    !isStudent(currentUser)
  ) {
    return true;
  }
  if (
    Object.values(CompanyAdminRoutes).find((route) =>
      matchPath(pathname, route),
    )
  ) {
    return true;
  }
  return false;
}
