import { defineMessages } from 'react-intl';

export default defineMessages({
  // main-menu
  search: {
    id: 'Header.config.student.search',
    defaultMessage: 'Rechercher',
    description: 'item Rechercher dans la main-menu',
  },
  dashboard: {
    id: 'Header.config.student.dashboard',
    defaultMessage: 'Dashboard',
  },
  favorites: {
    id: 'Header.config.student.favorites',
    defaultMessage: 'Favoris',
    description: 'item favorites dans la usernav',
  },
  affiliations: {
    id: 'Header.config.student.affiliations',
    defaultMessage: 'Programmes',
    description: 'item Programmes dans la usernav',
  },
  conversations: {
    id: 'Header.config.student.conversations',
    defaultMessage: 'Messages',
    description: 'item conversations dans la usernav',
  },
  appointments: {
    id: 'Header.config.student.appointments',
    defaultMessage: 'Rendez-vous',
    description: 'item appointments dans la usernav',
  },

  // usernav
  profile: {
    id: 'Header.config.student.profile',
    defaultMessage: 'Profil',
    description: 'item profil dans la usernav',
  },
  settings: {
    id: 'Header.config.student.settings',
    defaultMessage: 'Paramètres',
    description: 'item setting dans la usernav',
  },
  logout: {
    id: 'Header.config.student.logout',
    defaultMessage: 'Se déconnecter',
    description: 'item deconnexion dans la usernav',
  },

  schoolCoachMode: {
    id: 'Header.config.student.schoolCoachMode',
    defaultMessage: 'Accès Vocation',
    description: 'switch vers la plateforme vocation dans le header',
  },
  mjgNewBO: {
    id: 'Header.config.student.mjgNewBO',
    defaultMessage: 'BO MJG',
    description: 'item deconnexion dans la usernav',
  },
});
